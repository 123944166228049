import { Grid, Typography } from '@material-ui/core';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { companyFibonacciData } from '../apiService/tradaApiService';
import FRECandleStickChart from '../common/FRECandleGraph';
import FRECandleStickChart30min from '../common/FRECandleFor30Min';
import FRECandleStickChart1hr from '../common/FRECandle1Hr';
import dayjs from 'dayjs';
import { candleGraphFor15min, NiftyPlusPlus } from '../apiService/tickerApiService';
import { ArrowDownward, ArrowUpward, Height } from '@material-ui/icons';
import CandleStickIndices from '../common/CandleStickIndices';
import FREGraphForCandleStickChart from '../common/FREGraphForCandleStickChart';

function FRECandleGraph(props) {
    const { value, dataFromChild } = props;
    const intervalId = useRef(null);
    const { token, companySymbol } = dataFromChild;
    const [data15min, setData15min] = useState([]);
    const [data30min, setData30min] = useState([]);
    const [data1hr, setData1hr] = useState([]);
    const [dataYaxis15min_d, setDataYaxis15min_d] = useState([]);
    const [dataYaxis30min_w, setDataYaxis30min_w] = useState([]);
    const [dataYaxis_m, setDataYaxis_m] = useState([]);
    const [keys_d, setKeys_d] = useState([]);
    const [keys_w, setKeys_w] = useState([]);
    const [keys_m, setKeys_m] = useState([]);
    const [p_p_data, setP_p_data] = useState({});
    const [speedoMeterData, setSpeedoData] = useState([]);
    const [view, setView] = useState(false);

    const getCanldesData = async (tok, Symbol) => {
        try {
            const currentDate = dayjs().format('YYYY-MM-DD');
            const [candlesDataforGraph, candlesDataforGraph_30, candlesDataforGraph_60] = await Promise.all([
                candleGraphFor15min(tok, currentDate, currentDate, "15"),
                candleGraphFor15min(tok, currentDate, currentDate, "30"),
                candleGraphFor15min(tok, currentDate, currentDate, "60")
            ]);

            const timeStampData = candlesDataforGraph?.data?.map(e => ({
                x: new Date(e.x).getTime(),
                y: e.y
            })) || [];

            const timeStampData_30 = candlesDataforGraph_30?.data?.map(e => ({
                x: new Date(e.x).getTime(),
                y: e.y
            })) || [];

            const timeStampData_60 = candlesDataforGraph_60?.data?.map(e => ({
                x: new Date(e.x).getTime(),
                y: e.y
            })) || [];
            setData15min(timeStampData);
            setData30min(timeStampData_30);
            setData1hr(timeStampData_60);
        } catch (error) {
            console.error("Error fetching or processing data:", error);
        }
    };

    const fetchDataForSpeedometer = async (token, companySymbol) => {
        const company_speedo_data = await NiftyPlusPlus(companySymbol, token);
        setSpeedoData(company_speedo_data?.data);
    };

    const getHold = (v) => {
        if (v <= 20) return "Bearish++"
        else if (v > 20 && v <= 40) return "Bearish+"
        else if (v > 40 && v <= 60) return "Neutral"
        else if (v > 60 && v <= 80) return "Bullish+"
        else if (v > 80) return "Bullish++"
    }

    const getArrow = (arrow) => {
        switch (arrow) {
            case "UP": return <ArrowUpward color="secondary" style={{ fontSize: "18px", color: "#339249" }} />;
            case "DOWN": return <ArrowDownward style={{ fontSize: "18px", color: "#DA2424" }} />;
            case "NORMAL": return <Height style={{ rotate: "90deg", color: "#b5812a" }} />;
            default: return null;
        }
    };

    const setPPoints = async (Symbol) => {
        const pp_data = await companyFibonacciData(Symbol);
        setP_p_data(pp_data?.data);
        setDataYaxis15min_d(Object.values(pp_data?.data?.dailyData || {}));
        setDataYaxis30min_w(Object.values(pp_data?.data?.weeklyData || {}));
        setDataYaxis_m(Object.values(pp_data?.data?.monthlyData || {}));
        setKeys_d(Object.keys(pp_data?.data?.dailyData || {}));
        setKeys_w(Object.keys(pp_data?.data?.weeklyData || {}));
        setKeys_m(Object.keys(pp_data?.data?.monthlyData || {}));
    };

    useEffect(() => {
        if (companySymbol) setPPoints(companySymbol);

        intervalId.current = setInterval(() => {
            if (token && companySymbol) {
                fetchDataForSpeedometer(token, companySymbol);
                getCanldesData(token, companySymbol);
            }
        }, 5000);

        return () => {
            clearInterval(intervalId.current);
        };
    }, [token, companySymbol]);

    useMemo(() => {
        if (token && companySymbol) {
            fetchDataForSpeedometer(token, companySymbol);
            getCanldesData(token, companySymbol);
        }
    }, [token, companySymbol]);

    const getColor = (v) => {  
        if (v <= 20) return "#da2424"
        else if (v > 20 && v <= 40) return "#f74f4f"
        else if (v > 40 && v <= 60) return "#ffb200e3"
        else if (v > 60 && v <= 80) return "#44db67"
        else if (v > 80) return "#339249"
        
    }

    return (
        <>
            {speedoMeterData?.weekly?.length > 0 ?
                <Grid className='w-full flex gap-1 h-full'>
                    <Grid className='h-full' style={{ width: "55%", position: 'relative' }}>
                        <Grid style={{ background: '#000', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                            <Grid>  <Typography style={{ color: getColor(Object.values(speedoMeterData)?.length > 0 ? speedoMeterData.daily[0]?.percentage?.toFixed(2) : 0), marginLeft: "3px", alignItems: 'center', display: 'flex' }} className='font-futura'>
                                <span style={{ fontFamily: 'Futura PT Heavy', color: '#fff', fontSize: '13px' }}>15 Mins &nbsp;</span>
                                <span style={{ fontFamily: 'Futura PT Heavy', fontSize: "18px" }}> {getHold(Object.values(speedoMeterData)?.length > 0 ? speedoMeterData.daily[0]?.percentage : 0)}</span>
                                <span style={{ fontSize: '13px', marginLeft: '5px' }}> ({Object.values(speedoMeterData)?.length > 0 ? speedoMeterData.daily[0]?.percentage?.toFixed(2) : 0}%)</span>
                            </Typography></Grid>
                            <Grid style={{ width: '50%', zIndex: '99', left: '25%' }}>
                                <CandleStickIndices open={speedoMeterData?.daily?.[0]?.current_open ? speedoMeterData?.daily?.[0]?.current_open : speedoMeterData?.daily?.[0]?.ticker_open}
                                    high={speedoMeterData?.daily?.[0]?.current_high >= speedoMeterData?.daily?.[0]?.ticker_high ? speedoMeterData?.daily?.[0]?.current_high : speedoMeterData?.daily?.[0]?.ticker_high}
                                    low={speedoMeterData?.daily?.[0]?.current_low >= speedoMeterData?.daily?.[0]?.ticker_low ? speedoMeterData?.daily?.[0]?.ticker_low : speedoMeterData?.daily?.[0]?.current_low == 0 ? speedoMeterData?.daily?.[0]?.ticker_low : speedoMeterData?.daily?.[0]?.current_low}
                                    close={speedoMeterData?.daily?.[0]?.current_close} poscount={speedoMeterData?.daily?.[0]?.poscount} negcount={speedoMeterData?.daily?.[0]?.negcount} arrow={speedoMeterData?.daily?.[0]?.arrow} totalMetrics={speedoMeterData?.daily?.[0]?.totalMetrics} prev_range={speedoMeterData?.daily?.[0]?.pre_percentage}
                                    percentage={speedoMeterData?.daily?.[0]?.percentage}
                                />
                            </Grid>
                        </Grid>
                        {/* <FRECandleStickChart yAxisValues={dataYaxis15min_d} graphData={data15min} keys={keys_d} data={p_p_data?.dailyData} /> */}
                        <FREGraphForCandleStickChart token={token} min="15" companySymbol={companySymbol} tf="dailyData" ht="395" />
                    </Grid>
                    <Grid style={{ width: "23%", marginRight: '5px', position: 'relative' }}>
                        <Grid style={{ background: '#000', display: 'flex', justifyContent: 'center' }}>
                            <Typography style={{ color: getColor(Object.values(speedoMeterData)?.length > 0 ? speedoMeterData.weekly[0]?.percentage?.toFixed(2) : 0), marginLeft: "3px", alignItems: 'center', display: 'flex' }} className='font-futura'>
                                <span style={{ fontFamily: 'Futura PT Heavy', color: '#fff', fontSize: '13px' }}>30 Mins &nbsp;</span>
                                <span style={{ fontFamily: 'Futura PT Heavy', fontSize: "18px" }}> {getHold(Object.values(speedoMeterData)?.length > 0 ? speedoMeterData.weekly[0]?.percentage : 0)}</span>
                                <span style={{ fontSize: '13px', marginLeft: '5px' }}>  ({Object.values(speedoMeterData)?.length > 0 ? speedoMeterData.weekly[0]?.percentage?.toFixed(2) : 0}%)</span>
                            </Typography>
                        </Grid>
                        <Grid style={{ position: 'absolute', width: '90%', zIndex: '99', left: '5%' }}>
                            <CandleStickIndices open={speedoMeterData?.weekly?.[0]?.current_open ? speedoMeterData?.weekly?.[0]?.current_open : speedoMeterData?.weekly?.[0]?.ticker_open}
                                high={speedoMeterData?.weekly?.[0]?.current_high >= speedoMeterData?.weekly?.[0]?.ticker_high ? speedoMeterData?.weekly?.[0]?.current_high : speedoMeterData?.weekly?.[0]?.ticker_high}
                                low={speedoMeterData?.weekly?.[0]?.current_low >= speedoMeterData?.weekly?.[0]?.ticker_low ? speedoMeterData?.weekly?.[0]?.ticker_low : speedoMeterData?.weekly?.[0]?.current_low == 0 ? speedoMeterData?.weekly?.[0]?.ticker_low : speedoMeterData?.weekly?.[0]?.current_low}
                                close={speedoMeterData?.weekly?.[0]?.current_close} poscount={speedoMeterData?.weekly?.[0]?.poscount} negcount={speedoMeterData?.weekly?.[0]?.negcount} arrow={speedoMeterData?.weekly?.[0]?.arrow} totalMetrics={speedoMeterData?.weekly?.[0]?.totalMetrics} prev_range={speedoMeterData?.weekly?.[0]?.pre_percentage}
                                percentage={speedoMeterData?.weekly?.[0]?.percentage}
                            />
                        </Grid>
                        {/* <FRECandleStickChart30min yAxisValues={dataYaxis30min_w} graphData={data30min} from30={true} keys={keys_w} data={p_p_data?.weeklyData} /> */}
                        <FREGraphForCandleStickChart token={token} min="30" companySymbol={companySymbol} tf="weeklyData" ht="418" />
                    </Grid>
                    <Grid style={{ width: "20%", position: 'relative' }}>
                        <Grid style={{ background: '#000', display: 'flex', justifyContent: 'center', paddingTop: '3px' }}>
                            <Typography style={{ color: getColor(Object.values(speedoMeterData)?.length > 0 ? speedoMeterData.monthly[0]?.percentage?.toFixed(2) : 0), marginLeft: "3px", alignItems: 'center', display: 'flex' }} className='font-futura'>
                                <span style={{ fontFamily: 'Futura PT Heavy', color: '#fff', fontSize: '13px' }}>1 Hr &nbsp;</span>
                                <span style={{ fontFamily: 'Futura PT Heavy', fontSize: "18px" }}> {getHold(Object.values(speedoMeterData)?.length > 0 ? speedoMeterData.monthly[0]?.percentage : 0)}</span>
                                <span className='mr-1' style={{ fontSize: '13px' }}>({Object.values(speedoMeterData)?.length > 0 ? speedoMeterData.monthly[0]?.percentage?.toFixed(2) : 0}%)</span>
                            </Typography>
                        </Grid>
                        < Grid style={{ position: 'absolute', width: '100%', zIndex: '99', }}>
                            <CandleStickIndices open={speedoMeterData?.monthly?.[0]?.current_open ? speedoMeterData?.monthly?.[0]?.current_open : speedoMeterData?.monthly?.[0]?.ticker_open}
                                high={speedoMeterData?.monthly?.[0]?.current_high >= speedoMeterData?.monthly?.[0]?.ticker_high ? speedoMeterData?.monthly?.[0]?.current_high : speedoMeterData?.monthly?.[0]?.ticker_high}
                                low={speedoMeterData?.monthly?.[0]?.current_low >= speedoMeterData?.monthly?.[0]?.ticker_low ? speedoMeterData?.monthly?.[0]?.ticker_low : speedoMeterData?.monthly?.[0]?.current_low == 0 ? speedoMeterData?.monthly?.[0]?.ticker_low : speedoMeterData?.monthly?.[0]?.current_low}
                                close={speedoMeterData?.monthly?.[0]?.current_close} poscount={speedoMeterData?.monthly?.[0]?.poscount} negcount={speedoMeterData?.monthly?.[0]?.negcount} arrow={speedoMeterData?.monthly?.[0]?.arrow} totalMetrics={speedoMeterData?.monthly?.[0]?.totalMetrics} prev_range={speedoMeterData?.monthly?.[0]?.pre_percentage}
                                percentage={speedoMeterData?.monthly?.[0]?.percentage}
                            />
                        </Grid>
                        {/* <FRECandleStickChart1hr yAxisValues={dataYaxis_m} graphData={data1hr} keys={keys_m} data={p_p_data?.monthlyData} /> */}
                        <FREGraphForCandleStickChart token={token} min="60" companySymbol={companySymbol} tf="monthlyData" ht="415" />
                    </Grid>
                </Grid> : <Typography>Loading...</Typography>}
        </>
    );
}

export default FRECandleGraph;
