import { Grid, Typography } from '@material-ui/core'
import React from 'react'
import Indeices from './Indeices'
import TopTens from './TopTens'
import withAuth from '../../utils/withAuth'

const indiceTabsList = [
    { value: "0", label: 'NIFTY 50', },
    { value: "1", label: 'BANK NIFTY', },
    { value: "2", label: 'IT NIFTY', },
    { value: "3", label: 'NIFTY NEXT 50', },
    { value: "4", label: 'FIN NIFTY', },
    { value: "5", label: 'MID CAP SELECT', },
    // { value: "6", label: 'FUTURES+', },
    { value: "7", label: 'NIFTY+', },
    { value: "8", label: 'NSE+', },
    { value: "9", label: 'NSE++', },
    { value: "10", label: 'FUTURES++', },
]

const toptens = [
    { value: "0", label: 'NIFTY 10', },
    { value: "1", label: 'BANK NIFTY 10', },
    { value: "2", label: 'IT NIFTY 10', },
    { value: "3", label: 'MID CAP SELECT 10', },
    { value: "4", label: 'FIN NIFTY 10', },
]

function IndexMoversSeg(props) {
    const { submenu } = props

    return (
        <>
            {submenu == "0" &&
                <Grid className='w-full'>
                    <Indeices tabsList={indiceTabsList} />
                </Grid>
            }
            {submenu == "1" &&
                <Grid className='w-full'>
                    <TopTens tabsList={toptens}/>
                </Grid>
            }
        </>
    )
}

export default withAuth(IndexMoversSeg)