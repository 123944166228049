import { FormControlLabel, FormGroup, Grid, Switch } from '@material-ui/core'
import React, { useState } from 'react'
import IndiceCandleGraphs from './IndiceCandleGraphs'
import FRECandleGraph from './FRECandleGraph'

function IndiceCandleGraphView(props) {
    const { value, dataFromChild } = props
    const { token, companySymbol } = dataFromChild
    const [view, setView] = useState(true)

    const onViewClick = (e) => {
        setView(e.target.checked)
    }

    return (
        <>
            {!view ?
                <IndiceCandleGraphs value={value} dataFromChild={dataFromChild} /> :
                <FRECandleGraph value={value} dataFromChild={dataFromChild} />}
            <Grid style={{ margin: '-12px 10px 2px 0px', display: 'flex', justifyContent: 'end' }}>
                <FormGroup className="switch-view" onClick={onViewClick} >
                    <FormControlLabel control={<Switch  checked={view} size="small" />} label="FRE Graph" />
                </FormGroup>
            </Grid>
        </>
    )
}

export default IndiceCandleGraphView