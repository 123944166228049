import dayjs from 'dayjs';
import React, { useState, useEffect, useMemo, useRef } from 'react';
import Chart from 'react-apexcharts';
import { companyApiData, companyFibonacciData } from '../apiService/tradaApiService';
import { candleGraphApi } from '../apiService/tickerApiService';

const hregex = /H\d+/;
const lregex = /L\d+/;

const FREGraphForCandleStickChart = (props) => {
    const { token, min, companySymbol, yAxisValues, keys, tf, ht } = props;
    const [darkMode, setDarkMode] = useState(false);
    const [seriesData, setSeriesData] = useState([]);
    const [tfData, setTFData] = useState({});
    const [keysForGraph, setKeysForGraph] = useState({});
    const containerRef = useRef(null);
    const annotationsRef = useRef([]);

    const fetchData = async (t, s, m) => {
        const pivotPointsData = await companyFibonacciData(s);
        const currentDate = dayjs().format('YYYY-MM-DD');
        let candlesDataForGraph = await candleGraphApi(t, currentDate, currentDate, m);

        if (Array.isArray(candlesDataForGraph?.data)) {
            const a = candlesDataForGraph.data.map(e => ({
                x: new Date(e?.x).getTime(),
                y: e?.y,
            }));
            setSeriesData([{ data: a }]);
        } else {
            console.error('candlesDataForGraph.data is not an array', candlesDataForGraph);
            setSeriesData([{ data: [] }]);
        }
        setTFData(pivotPointsData?.data || {});
        setKeysForGraph(pivotPointsData?.data[tf] || []);
    };

    useEffect(() => {
        let intervalId;
        intervalId = setInterval(() => {
            if (token && min && companySymbol) {
                fetchData(token, companySymbol, min)
            };
        }, 5000);
        return () => {
            clearInterval(intervalId)
        }

    }, [token, companySymbol]);

    useMemo(() => {
        if (token && min && companySymbol) {
            fetchData(token, companySymbol, min)
        };
    }, [token, companySymbol])

    const getValuesByPattern = (data, pattern) => {
        if (!data) return {};
        return Object.keys(data).filter(key => key.startsWith(pattern)).reduce((acc, key) => {
            acc[key] = data[key];
            return data[key];
        }, {});
    };

    const getColor = (index, k_i) => {
        let color = hregex.test(k_i) ? "#339249" : lregex.test(k_i) ? "#DA2424" : "#4D8FCC"
        return color
    }

    const getPersent = (values, yValues) => {
        return (((values - Math.min(...yValues)) / (Math.max(...yValues) - Math.min(...yValues))) * 100)?.toFixed(1)
    }

    const getBorderWidth = (d, v) => {
        if (!d) return "2px"; // Default border width if data is undefined
        let r2 = Math.max(getValuesByPattern(d, "SPH1"), getValuesByPattern(d, "FPH2"));
        let r1 = Math.min(getValuesByPattern(d, "SPH1"), getValuesByPattern(d, "FPH2"));
        let s1 = Math.max(getValuesByPattern(d, "SPL1"), getValuesByPattern(d, "FPL2"));
        let s2 = Math.min(getValuesByPattern(d, "SPL1"), getValuesByPattern(d, "FPL2"));

        if (r2 === v || r1 === v || s1 === v || s2 === v) return "5px";
        return "2px";
    };

    const fibonacciFontColor = (index, keys) => {
        let color;
        let i = keys[index]
        switch (i) {
            case "250", "238", "223", "200", "178", "150", "123", "78", "50", "23":
            case "neg23", "neg50", "neg78", "neg100", "neg123", "neg150":
                color = "#fff";
                return color
            case "0", "161":
                color = "#f67239"
                return color
            case "138":
            case "neg38":
            case "neg138":
                color = "#76c7ff"
                return color
            case "100":
            case "neg61":
                color = "#70ff3f"
                return color
            case "61":
                color = "#e6ff41"
                return color
            case "38":
                color = "#fc9f32"
                return color
            default:
                color = "#fff"
                return color
        }
    }
    const getFREPrec = (v) => {
        switch (v) {
            case "0": return "0"
            case "23": return "23.6"
            case "38": return "38.2"
            case "50": return "50"
            case "61": return "61.8"
            case "78": return "78.6"
            case "100": return "100"
            case "123": return "123.6"
            case "138": return "138.2"
            case "150": return "150"
            case "161": return "161.8"
            case "178": return "178.6"
            case "200": return "200"
            case "223": return "223.6"
            case "238": return "238.2"
            case "250": return "250"
            case "neg23": return "-23.6"
            case "neg38": return "-38.2"
            case "neg50": return "-50"
            case "neg61": return "-61.8"
            case "neg78": return "-78.6"
            case "neg100": return "-100"
            case "neg123": return "-123.6"
            case "neg138": return "-138.2"
            case "neg150": return "-150"
            default: return "0"
        }
    }

    const getAlignment = (v) => {
        const containerWidth = containerRef.current ? containerRef.current.offsetWidth : 250;
        switch(v){
            case "15": return ( 0.036 * containerWidth);
            case "30": return  0.075 * containerWidth;
            case "60": return  0.13 * containerWidth;
        }

    }


    const getYaxisArray = (a) => {
        const containerWidth = containerRef.current ? containerRef.current.offsetWidth : 250;
        const percentageOffsetX = -0.05 * containerWidth;

        return Object.values(a)?.map((value, i) => ({
            y: value,
            borderWidth: "2px",
            borderColor: fibonacciFontColor(i, Object.keys(a)),
            strokeDashArray: 0,
            label: {
                borderColor: '#00E396',
                borderWidth: '0px',
                style: {
                    display: 'block',
                    fontSize: '10px',
                    color: fibonacciFontColor(i, Object.keys(a)),
                    background: 'transparent',
                    fontWeight: "bold"
                },
                orientation: 'horizontal',
                offsetX: getAlignment(min),
                offsetY: 0,
                text: `${value?.toFixed(1)} (${getFREPrec(Object.keys(a)?.[i])}%)`,
            },
        })) || [];
    };

    const options = useMemo(() => ({
        chart: {
            type: 'candlestick',
            height: 390,
            background: darkMode ? '#1e1e1e' : '#000',
            toolbar: {
                show: false,
                tools: { zoom: false, zoomin: false, zoomout: false, pan: true, reset: false },
            },
            zoom: {
                enabled: false,
                type: 'x',
                autoScaleYaxis: true
            },
        },
        xaxis: {
            type: 'datetime',
            labels: {
                style: {
                    colors: darkMode ? '#fff' : '#fff',
                    fontSize: '12px'
                },
                formatter: (val) => dayjs(val).format('HH:mm')
            },
            axisBorder: { color: darkMode ? '#777' : '#ddd' },
            axisTicks: { color: darkMode ? '#777' : '#ddd' }
        },
        yaxis: {
            labels: { show: true, style: { colors: darkMode ? '#fff' : '#000' } },
            axisBorder: { show: false, color: darkMode ? '#777' : '#ddd' },
            axisTicks: { show: false, color: darkMode ? '#777' : '#ddd' },
            tooltip: { enabled: true },
        },
        grid: {
            show: true,
            borderColor: '#000',
            yaxis: {
                lines: {
                    show: true,
                    color: '000',
                    width: 1,
                    dashArray: 4
                }
            },
            padding: { left: -60, right: 10 },
        },
        annotations: {
            yaxis: Object.keys(tfData).length > 0 ? getYaxisArray(tfData[tf]) : []
        },
        tooltip: {
            enabled: true,
            shared: true,
            intersect: false,
            followCursor: true,
            theme: darkMode ? 'dark' : 'light',
            x: {
                show: true,
                formatter: (val) => dayjs(val).format('DD MMM YYYY, HH:mm'),
            },
            y: {
                show: true,
                formatter: (val) => val.toFixed(2),
                title: { formatter: () => 'Price: ' },
            },
            style: { fontSize: '12px' },
        },
        plotOptions: {
            candlestick: {
                colors: {
                    upward: '#4CAF50',
                    downward: '#F44336',
                },
                wick: {
                    useFillColor: true,
                },
            },
        },
    }), [darkMode, tfData]);

    const series = useMemo(() => {
        return seriesData.length > 0 ? seriesData : [{ data: [] }];
    }, [seriesData]);

    return (
        <div  className='chart-container' ref={containerRef}>
            {series.length > 0 ? (
                <Chart
                    options={options}
                    series={series}
                    type="candlestick"
                    height={ht ? ht : 390}
                />
            ) : (
                <p>Loading data...</p>
            )}
        </div>
    );
};

export default FREGraphForCandleStickChart;