import { Box, Grid, makeStyles, MenuItem, Select, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Tooltip, Typography } from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import React, { useEffect, useMemo, useState } from 'react'
import { CurrentCompanyData, HeatsMapData } from '../apiService/tickerApiService';
import { getTooltip } from '../../utils';
import { ArrowDropDown, ArrowDropUp, ImportExport } from '@material-ui/icons';
import IndicesStrip from '../common/IndicesStrip';
import PercentagesView from './PercentagesView';
import PointsView from './PointsView';
import withAuth from '../../utils/withAuth';

const NavList = [
  { value: "0", label: 'NIFTY 50', },
  { value: "1", label: 'BANK NIFTY', },
  { value: "2", label: 'FIN NIFTY', },
  { value: "3", label: 'NIFTY IT', },
  { value: "4", label: 'NEXT 50', },
  { value: "5", label: 'ETFs', },
  { value: "6", label: 'INDICES', }
]

const useStyles = makeStyles((theme) => ({
  headerCell: {
    border: '1px solid white',
    color: '#333333',
    textAlign: 'start',
    // width : "200px",
    padding: "0px",
    paddingBottom: "0px"
  },
  tableCell: {
    border: '1px solid white',
    textAlign: 'center',
    color: "#ffffff",
    fontWeight: "600",
    padding: "4px"
  },
  table: {
    border: '0px solid',
    width: '100%',
    borderCollapse: 'collapse',
  },
  topContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#154A72",
    padding: "6px 5px"
  },
  timeDur: {
    fontFamily: 'Futura PT Book',
    // height: "30px",
    // color: "#ffffff",
    fontWeight: 'bold',
    fontSize: "15px",
    // display: "flex",
    // width:"100%",
    alignItems: "center",
    transform: "all 1s ease-in-out",
    '&:hover': {
      transform: "scale(1.15)"
    },
  },
  greenPerc: {
    color: '#03781E',
    fontWeight: "bold",
    fontSize: "12px",
    backgroundColor: '#fff',
    borderRadius: "5px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: "center",
    height: "17px",
    padding: '0px 5px'
  },
  redPerc: {
    color: '#C33E3E',
    fontWeight: "bold",
    fontSize: "12px",
    backgroundColor: '#fff',
    borderRadius: "5px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: "center",
    height: "fit-content",
    padding: '0px 5px'
  },
  high: {
    backgroundColor: "#DCE9F3",
    width: "100%",
    borderRight: "1px solid white",
    paddingTop: "5px"
  },
  highFont: {
    fontSize: "12px",
    fontWeight: "bold",
    color: "#339249",
    fontFamily: 'Futura PT Book',
  },
  low: {
    backgroundColor: "#DCE9F3",
    width: "100%",
    borderRight: "1px solid white",
    paddingBottom: "3px",
    paddingTop: "3px"
  },
  lowFont: {
    fontFamily: 'Futura PT Book',
    fontSize: "12px",
    fontWeight: "bold",
    color: "#DA2424"
  },
  prediction: {
    backgroundColor: "#DCE9F3",
    fontFamily: 'Futura PT Book',
    fontSize: "12px",
    fontWeight: "bold",
    color: "#444444",
    display: 'flex',
    justifyContent: 'center',
  },
  num: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#000000"
  },
  companyName: {
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
    color: "#154a72",
    backgroundColor: "#fff",
    padding: "6px",
    borderLeft: '1px solid lightgray'
  }
}));
function Heatmaps(props) {
  const { submenuHm, setSubmenuHm, tabsList } = props
  const classes = useStyles()
  const [value, setValue] = useState('0');
  const [view_hm, setView_hm] = useState('0');
  const [label, setlabel] = useState(NavList[0].label);
  const [view, setView] = useState("1");
  const [data, setData] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [cp_hov, setCp_hov] = useState([]);
  const [diff, setDiff] = useState([]);
  const [headValue, setHeadValue] = useState("")
  const [sortDirection, setSortDirection] = useState("desc");
  const [tickerRes, setTickerRes] = useState(0);
  const [closePrice, setClosePrice] = useState(0);
  const [curr_perc, setCurr_perc] = useState(0);
  const [sortby_tf, setSortBy_tf] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
    let a = NavList.filter((e, i) => e.value === newValue)
    setlabel(a.length ? a[0].label : NavList[0].label)
    setHeadValue("")
    setSortBy_tf("")
  };

  const handleChangeView = (e, val) => {
    setData([])
    setHeadValue("")
    setSortBy_tf("")
    setView_hm(val);
    // let a = tabsList.filter((e, i) => e.value === val)
    // setlabel(a.length ? a[0].label : NavList[0].label)
  }

  const currentMP = async (token, sort) => {
    if (value != '5' && value != '6') {

      const CMPdata = await CurrentCompanyData(token);
      setTickerRes(CMPdata?.data)
      setClosePrice(CMPdata?.data?.closePrice)
      setCurr_perc((CMPdata?.data?.change)?.toFixed(2))
    }
    view_hm === "0" && get_H_data(sort)
  }

  useEffect(() => {
    if (submenuHm) {
      setView_hm(submenuHm)
      // const a = NavList.filter(e => e.value == submenuHm ? e.label : "")
      // setlabel(a.length > 0 ? a[0].label : NavList[0].label)
    } else {
      setView_hm("0");
      // setlabel(NavList[0].label)
    }
  }, [submenuHm])

  const filterToken = (v) => {
    switch (v) {
      case "0": return 256265
      case "1": return 260105
      case "2": return 257801
      case "3": return 259849
      case "4": return 270857
      case "5": return 4885505
      case "6": return 1
      default: return 0
    }
  }
  const filterLabel = (v) => {
    switch (v) {
      case "0": return "nifty50"
      case "1": return "banknifty"
      case "2": return "finnifty"
      case "3": return "itnifty"
      case "4": return "next50"
      case "5": return "etfs"
      case "6": return "indices"
      default: return ""
    }
  }

  const filterValue = (symbol, tf, data) => {
    let s = data[tf].filter((e, i) => {
      if (e.companySymbol === symbol) {
        return e.changePercentage
      }
    })
    return `${(s[0]?.changePercentage ?? 0)?.toFixed(2)}`
  }


  const sortingData = (data, tf, direction) => {
    let sortedData = data[tf].sort((a, b) => {
      if (direction === "asc") {
        return a.changePercentage - b.changePercentage;
      } else {
        return b.changePercentage - a.changePercentage;
      }
    }).map((e, i) => {
      switch (tf) {
        case 'D':
          return {
            companySymbol: e.companySymbol,
            cmp: e.currentMarketPrice,
            daily: `${e.changePercentage?.toFixed(2)}`,
            weekly: filterValue(e.companySymbol, "W", data),
            monthly: filterValue(e.companySymbol, "M", data),
            quarterly: filterValue(e.companySymbol, "Q", data),
            halfYearly: filterValue(e.companySymbol, "H", data),
            yearly: filterValue(e.companySymbol, "Y", data)
          };
        case 'W':
          return {
            companySymbol: e.companySymbol,
            cmp: e.currentMarketPrice,
            daily: filterValue(e.companySymbol, "D", data),
            weekly: `${e.changePercentage?.toFixed(2)}`,
            monthly: filterValue(e.companySymbol, "M", data),
            quarterly: filterValue(e.companySymbol, "Q", data),
            halfYearly: filterValue(e.companySymbol, "H", data),
            yearly: filterValue(e.companySymbol, "Y", data)
          };
        case 'M':
          return {
            companySymbol: e.companySymbol,
            cmp: e.currentMarketPrice,
            daily: filterValue(e.companySymbol, "D", data),
            weekly: filterValue(e.companySymbol, "W", data),
            monthly: `${e.changePercentage?.toFixed(2)}`,
            quarterly: filterValue(e.companySymbol, "Q", data),
            halfYearly: filterValue(e.companySymbol, "H", data),
            yearly: filterValue(e.companySymbol, "Y", data)
          };
        case 'Q':
          return {
            companySymbol: e.companySymbol,
            cmp: e.currentMarketPrice,
            daily: filterValue(e.companySymbol, "D", data),
            weekly: filterValue(e.companySymbol, "W", data),
            monthly: filterValue(e.companySymbol, "M", data),
            quarterly: `${e.changePercentage?.toFixed(2)}`,
            halfYearly: filterValue(e.companySymbol, "H", data),
            yearly: filterValue(e.companySymbol, "Y", data)
          };
        case 'H':
          return {
            companySymbol: e.companySymbol,
            cmp: e.currentMarketPrice,
            daily: filterValue(e.companySymbol, "D", data),
            weekly: filterValue(e.companySymbol, "W", data),
            monthly: filterValue(e.companySymbol, "M", data),
            quarterly: filterValue(e.companySymbol, "Q", data),
            halfYearly: `${e.changePercentage?.toFixed(2)}`,
            yearly: filterValue(e.companySymbol, "Y", data)
          };
        case 'Y':
          return {
            companySymbol: e.companySymbol,
            cmp: e.currentMarketPrice,
            daily: filterValue(e.companySymbol, "D", data),
            weekly: filterValue(e.companySymbol, "W", data),
            monthly: filterValue(e.companySymbol, "M", data),
            quarterly: filterValue(e.companySymbol, "Q", data),
            halfYearly: filterValue(e.companySymbol, "H", data),
            yearly: `${e.changePercentage?.toFixed(2)}`
          };
        default:
          return data;
      }
    });

    return sortedData;
  };

  const setData_hover = (data, tf) => {
    let x = data[tf].map((e, i) => {
      if (e.companySymbol) {
        return e.companySymbol
      }
    })
    return x
  }

  const get_H_data = async (s) => {
    const filteredLabel = filterLabel(value);
    const response = await HeatsMapData(filteredLabel);

    if (response?.data?.success) {
      const data = response?.data?.data;
      let sortedData, companyListData, diffData, cpData;

      const handleSorting = (timeframe) => {
        sortedData = sortingData(data, timeframe, sortDirection);
        companyListData = setData_hover(data, timeframe);
        diffData = data[timeframe].map(e => e.difference);
        cpData = data[timeframe].map(e => e.currentMarketPrice?.toFixed(2));
      };

      switch (sortby_tf) {
        case "D":
          handleSorting("D");
          break;
        case "W":
          handleSorting("W");
          break;
        case "M":
          handleSorting("M");
          break;
        case "Q":
          handleSorting("Q");
          break;
        case "H":
          handleSorting("H");
          break;
        case "Y":
          handleSorting("Y");
          break;
        default:
          companyListData = data?.D.map(e => e.companySymbol);
          cpData = data?.D.map(e => e.currentMarketPrice);
          diffData = data?.D.map(e => e.difference);

          sortedData = [];
          const formatData = (arr) => arr?.map(e =>
            Object.values(e).length ? `${(e.changePercentage)?.toFixed(2)}` : null
          );

          const datasets = ['D', 'W', 'M', 'Q', 'H', 'Y'].map(period => formatData(data?.[period]));
          datasets[0].forEach((_, i) => {
            sortedData.push({
              companySymbol: companyListData[i],
              cmp: cpData[i],
              daily: datasets[0][i],
              weekly: datasets[1][i],
              monthly: datasets[2][i],
              quarterly: datasets[3][i],
              halfYearly: datasets[4][i],
              yearly: datasets[5][i]
            });
          });
          break;
      }

      setCp_hov(cpData);
      setDiff(diffData);
      setCompanyList(companyListData);
      setData(sortedData);
    }
  };

  useEffect(() => {
    let token = filterToken(value);
    const intervalId = setInterval(() => {
      if (token) {
        currentMP(token, sortby_tf);
      }
    }, 1000);
    return () => {
      setData([])
      clearInterval(intervalId)
    };
  }, [value, sortby_tf, sortDirection, view_hm]);

  const objHead = [{ duration: "Daily", value: "D" },
  { duration: "Weekly", value: "W" },
  { duration: "Monthly", value: "M" },
  { duration: "Quarterly", value: "Q" },
  { duration: "Half Yearly", value: "H" },
  { duration: "Yearly", value: "Y" }]


  const filterCp = (i) => {
    const company = companyList[i]
    const c_m_p = cp_hov[i]
    const differ = diff[i]
    return `${company} ${c_m_p} (${differ?.toFixed(2)})`
  }


  const handleHeaderClick = (value) => {
    setHeadValue(value)
    setSortBy_tf(value)
    if (value === sortby_tf) {
      setSortDirection(prevDirection => prevDirection === "asc" ? "desc" : "asc");
    } else {
      setSortDirection("desc");
    }
  };

  const filterHMKey = (val) => {
    switch (val) {
      case "1": return "prerangepecentage"
      case "2": return "currentrangepecentage"
      case "3": return "score"
      case "4": return "camarilla"
      case "5": return "standerd"
      case "6": return "fibonacci"
      case "7": return "cprheatmaps"
      case "8": return "goldenratio"
      default: return ""
    }
  }

  return (
    <Grid className="tt-container block">
      <Tabs value={view_hm} onChange={handleChangeView} aria-label="Vertical tabs example" sx={{ borderRight: 1, borderColor: 'divider' }} style={{ backgroundColor: "#E5F1FB" }}>
        {tabsList?.map((el, i) => (
          <Tab className='top-tabs' key={i} label={el.label} value={el.value} style={{ minWidth: "fit-content", fontFamily: "Futura PT Book" }} />
        ))}
      </Tabs>
      <Grid className='flex border-t'>
        <Grid className='tabs-container' style={{ width: '13%' }}>
          <Box className="tt-tablist">
            <Tabs value={value} onChange={handleChange} orientation="vertical" variant="scrollable" aria-label="Vertical tabs example" sx={{ borderRight: 1, borderColor: 'divider' }}>
              {NavList?.map((e, i) => (
                <Tab className='tt-tab' key={i} label={e.label} value={e.value} style={{ minWidth: "fit-content" }} />
              ))}
            </Tabs>
          </Box>
        </Grid>
        <Grid className='tbl-container'>
          <TabContext value={value} >
            {/* <Tabs
            value={view_hm}
            onChange={handleChangeView}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: 'divider' }}
          >
          {tabsList?.map((el,i) => (
            <Tab className='tt-tab' key={i} label={el.label} value={el.value} style={{ minWidth: "fit-content" }}/>
          ))}
          </Tabs> */}
            <TabPanel value={value} className="tt-tablepanel">
              <Grid className="contributors">
                <Grid className="p-contributors" style={{ width: "100%" }}>
                  <Grid>
                    <Grid className='bees-ticker' style={{ display: "flex", justifyContent: 'space-between' }}>
                      <Grid className="tviewpricing green">
                        <Typography className="tview-context">{label}</Typography>
                        {value !== '5' && value !== "6" && <>
                          <p style={{ color: `${closePrice <= tickerRes?.lastTradedPrice ? 'rgb(51, 146, 73)' : 'rgb(218, 36, 36)'}`, }}> {tickerRes ? tickerRes?.lastTradedPrice : 0}</p>
                          <p style={{ fontSize: '11px', color: '#857d7d', marginLeft: '5px' }}>{tickerRes ? (tickerRes?.lastTradedPrice - closePrice)?.toFixed(2) : 0}</p>
                          <p style={{ fontSize: '11px', color: '#857d7d', marginLeft: '5px' }}>{`(${curr_perc ? curr_perc : 0}%)`}</p></>} </Grid>
                      <Grid className='flex'>
                        {label !== "NIFTY 50" && <IndicesStrip token="256265" IndiceLabel="NIFTY 50" />}
                        {label !== "BANK NIFTY" && <IndicesStrip token="260105" IndiceLabel="BANK NIFTY" />}
                        {label !== "NIFTY IT" && <IndicesStrip token="259849" IndiceLabel="IT NIFTY" />}
                      </Grid>
                    </Grid>
                  </Grid>
                  {view_hm === "0" ? <TableContainer style={{ borderRadius: "10px", height: `calc(100vh - 155px)`, padding: '0px 10px' }}> {/* {data.length ?  */}
                    <Table className={classes.table} stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.headerCell} style={{ width: "10%" }}>
                            <Grid className={classes.topContainer} style={{ justifyContent: "center" }}>
                              <Typography align='center' style={{ fontWeight: "bold", color: "#ffffff" }}>Counter</Typography>
                            </Grid>
                          </TableCell>
                          <TableCell className={classes.headerCell} style={{ width: "10%" }}>
                            <Grid className={classes.topContainer} style={{ justifyContent: "center" }}>
                              <Typography align='center' style={{ fontWeight: "bold", color: "#ffffff" }}>CMP</Typography>
                            </Grid>
                          </TableCell>
                          {objHead.map((e, i) => {
                            return (<>
                              <TableCell key={i} className={classes.headerCell} style={{ width: "13.3%" }}>
                                <Grid container direction="column">
                                  <Grid className={classes.topContainer} style={{
                                    justifyContent: "space-around",
                                    background: `${headValue === e.value ? sortDirection == "desc" ? `linear-gradient(45deg, #339249, transparent)` : `linear-gradient(45deg, #e26464, transparent)` : '#154A72'}`,
                                    color: `${headValue === e.value ? sortDirection == "desc" ? 'black' : 'black' : '#fff'}`,
                                  }} onClick={() => handleHeaderClick(e.value)} >
                                    <Tooltip title={getTooltip(e.duration)} arrow placement="top">
                                      <Typography align='center' className={classes.timeDur}>{e.duration}</Typography>
                                    </Tooltip>
                                    <Grid className='flex flex-col'>
                                      {/* {sortDirection=="desc"? <ArrowDropUp style={{ color: `${headValue === e.value ? 'green' : '#fff'}`,marginBottom:'-6px' }} />
                                 :<ArrowDropDown style={{ color: `${headValue === e.value ? 'red' : '#fff'}`,margin:'-6px'  }} />} */}
                                      <ImportExport style={{
                                        color: `${headValue === e.value ? sortDirection == "desc" ? 'green' : 'red' : '#fff'}`
                                      }} />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </TableCell>
                            </>
                            )
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.length ? data.map((item, index) => {
                          return (
                            <TableRow key={index} className='tableCell'>
                              <Tooltip title={filterCp(index)} arrow placement="top">
                                <TableCell className='tableCell text-center font-black p-1' >
                                  <Typography style={{ color: "#154A72" }} align='center'>{item?.companySymbol}</Typography>
                                </TableCell>
                              </Tooltip>
                              <Tooltip title={filterCp(index)} arrow placement="top">
                                <TableCell className='tableCell text-center font-black p-1' >
                                  <Typography style={{ color: "#154A72" }} align='center'>{item?.cmp}</Typography>
                                </TableCell>
                              </Tooltip>
                              <Tooltip title={filterCp(index)} arrow placement="top">
                                <TableCell className='tableCell text-center font-black p-1' >
                                  <Typography style={{ color: parseFloat(item?.daily) > 0 ? "#339249" : parseFloat(item?.daily) == 0 ? "#4D8FCC" : "#DA2424", }} align='center'>{item?.daily ? item?.daily : "--"}%</Typography>
                                </TableCell>
                              </Tooltip>
                              <Tooltip title={filterCp(index)} arrow placement="top">
                                <TableCell className='tableCell text-center font-black p-1' >
                                  <Typography style={{ color: parseFloat(item?.weekly) > 0 ? "#339249" : parseFloat(item?.weekly) == 0 ? "#4D8FCC" : "#DA2424", }} align='center'>{item?.weekly ? item?.weekly : "--"}%</Typography>
                                </TableCell>
                              </Tooltip>
                              <Tooltip title={filterCp(index)} arrow placement="top">
                                <TableCell className='tableCell text-center font-black p-1' >
                                  <Typography style={{ color: parseFloat(item?.monthly) > 0 ? "#339249" : parseFloat(item?.monthly) == 0 ? "#4D8FCC" : "#DA2424", }} align='center'>{item?.monthly ? item?.monthly : "--"}%</Typography>
                                </TableCell>
                              </Tooltip>
                              <Tooltip title={filterCp(index)} arrow placement="top">
                                <TableCell className='tableCell text-center font-black p-1' >
                                  <Typography style={{ color: parseFloat(item?.quarterly) > 0 ? "#339249" : parseFloat(item?.quarterly) == 0 ? "#4D8FCC" : "#DA2424", }} align='center'>{item?.quarterly ? item?.quarterly : "--"}%</Typography>
                                </TableCell>
                              </Tooltip>
                              <Tooltip title={filterCp(index)} arrow placement="top">
                                <TableCell className='tableCell text-center font-black p-1' >
                                  <Typography style={{ color: parseFloat(item?.halfYearly) > 0 ? "#339249" : parseFloat(item?.halfYearly) == 0 ? "#4D8FCC" : "#DA2424", }} align='center'>{item?.halfYearly ? item?.halfYearly : "--"}%</Typography>
                                </TableCell>
                              </Tooltip>
                              <Tooltip title={filterCp(index)} arrow placement="top">
                                <TableCell className='tableCell text-center font-black p-1' >
                                  <Typography style={{ color: parseFloat(item?.yearly) > 0 ? "#339249" : parseFloat(item?.yearly) == 0 ? "#4D8FCC" : "#DA2424", }} align='center'>{item?.yearly ? item?.yearly : "--"}%</Typography>
                                </TableCell>
                              </Tooltip>
                            </TableRow>
                          );
                        }) : <p>0</p>}
                      </TableBody>
                    </Table>
                  </TableContainer> : <></>}
                  {/* {view_hm === "1" ? <p>Previous Range%</p> : <></>} */}
                  {view_hm === "1" ? <PercentagesView value={filterLabel(value)} h_m_keyForApi={filterHMKey("1")} api_res_key="prev_range" /> : <></>}
                  {view_hm === "2" ? <p>Current Range%</p> : <></>}
                  {/* {view_hm === "1" ? <PercentagesView value={filterLabel(value)} h_m_keyForApi={filterHMKey("1")} api_res_key="prev_range" /> : <></>}
                  {view_hm === "2" ? <PercentagesView value={filterLabel(value)} h_m_keyForApi={filterHMKey("2")} api_res_key="current_range" /> : <></>}
                  {view_hm === "3" ? <PercentagesView value={filterLabel(value)} h_m_keyForApi={filterHMKey("3")} api_res_key="current_range" /> : <></>}
                  {view_hm === "4" ? <PercentagesView value={filterLabel(value)} h_m_keyForApi={filterHMKey("4")} api_res_key="current_range" /> : <></>}
                  {view_hm === "5" ? <PercentagesView value={filterLabel(value)} h_m_keyForApi={filterHMKey("5")} api_res_key="current_range" /> : <></>}
                  {view_hm === "6" ? <PercentagesView value={filterLabel(value)} h_m_keyForApi={filterHMKey("6")} api_res_key="current_range" /> : <></>}
                  {view_hm === "7" ? <PointsView value={filterLabel(value)} h_m_keyForApi={filterHMKey("7")} api_res_key="value" /> : <></>}
                  {view_hm === "8" ? <PointsView value={filterLabel(value)} h_m_keyForApi={filterHMKey("8")} api_res_key="value" /> : <></>} */}
                </Grid>
              </Grid>
            </TabPanel>
          </TabContext>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default withAuth(Heatmaps)
// <TableRow key={index}>
//   {Object.values(item).map((value, ind) => {
//     let number = parseFloat(value)//match[1]
//     return (
//       <Tooltip title={filterCp(index)} arrow placement="top">
//         <TableCell key={ind} className={classes.tableCell}
//           style={{ color: `${ind === 0 ? "#154A72" : number >= 0 ? "#339249" : "#DA2424"}`, border: "1px solid lightgray", backgroundColor: "#fff", fontFamily: 'Futura PT Book' }}>
//           {value}
//         </TableCell>
//       </Tooltip>
//     )
//   })}
// </TableRow>