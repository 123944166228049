import React, { useEffect, useMemo, useState } from 'react'
import { NiftyPlusPlus, NsePlusData } from '../apiService/tickerApiService'
import { Grid, Tab, Typography } from '@material-ui/core'
import MetricCandleStick from '../common/MetricCandleStick'
import GaugeChart from '../common/GaugeChart'
import './Indexmovers.css';
import { getColorPr, rangePerc } from '../../utils';
import { ArrowDownward, ArrowUpward, Height } from '@material-ui/icons'
import GaugeChartTF from '../common/GuageChartTF'
import { TabContext, TabList } from '@material-ui/lab'

function NSEPlusPlus() {
    const [nifty50Data, setNifty50Data] = useState({})
    const [bankNiftyData, setBankNiftyData] = useState({})
    const [itNiftyData, setItNiftyData] = useState({})
    const [finNiftyData, setFinNiftyData] = useState({})
    const [midcapSelectData, setMidCapSelectData] = useState({})
    // const [next50Data, setNext50Data] = useState({})
    const [rel_Data, setRel_Data] = useState({})

    const fetchDataForNsePlusPlus = async () => {
        const nifty50_data = await NiftyPlusPlus("nifty50", 256265)
        const bankNifty_data = await NiftyPlusPlus("banknifty", 260105)
        const itNifty_data = await NiftyPlusPlus("itnifty", 259849)
        const finnifty_data = await NiftyPlusPlus("finnifty", 257801)
        const midcapselect_data = await NiftyPlusPlus("midcapselect", 288009)
        const rel_res_data = await NiftyPlusPlus("RELIANCE", 738561)
        // const next50_data = await NiftyPlusPlus("next50", 270857)

        setNifty50Data(nifty50_data?.data)
        setBankNiftyData(bankNifty_data?.data)
        setItNiftyData(itNifty_data?.data)
        setFinNiftyData(finnifty_data?.data)
        setMidCapSelectData(midcapselect_data?.data)
        setRel_Data(rel_res_data?.data)
        // setNext50Data(next50_data?.data)
    }

    useEffect(() => {
        let intervalId = setInterval(() => {
            fetchDataForNsePlusPlus()
        }, 4000)
        return () => clearInterval(intervalId)
    }, [nifty50Data])

    useMemo(() => {
        fetchDataForNsePlusPlus()
    }, [])
    const getTF = (i, bool) => {
        switch (i) {
            case 0: return "NIFTY50"
            case 1: return "BANKNIFTY"
            case 2: return "ITNIFTY"
            case 3: return "FINNIFTY"
            case 4: return bool ? "MIDCAP SELECT" : "MIDCAP"
            case 5: return "RELIANCE"
            // case 5: return "NEXT50"
            default: return 0
        }
    }
    const getPerc = (cmp, pc) => {
        let a = ((cmp - pc) / pc) * 100
        return a?.toFixed(1)
    }

    const getColor = (v) => {  
        if (v <= 20) return "#da2424"
        else if (v > 20 && v <= 40) return "#f74f4f"
        else if (v > 40 && v <= 60) return "#ffb200e3"
        else if (v > 60 && v <= 80) return "#44db67"
        else if (v > 80) return "#339249"
        
    }
    const viewTabs = [
        { value: '1', label: 'Trader View', },
        { value: '2', label: 'Investor View', }
    ]
    const [value, setValue] = React.useState('1');
    const [label, setlabel] = React.useState(viewTabs[0].label)
    const handleChange = (event, newValue) => {
        setValue(newValue);
        let a = viewTabs.filter((e, i) => e.value === newValue)
        setlabel(a.length ? a[0].label : viewTabs[0].label)
    };
    return (
        <Grid className='niftyplus flex w-full gap-1 bg-white'>
            <Grid className="investers-trading-tabs" style={{ width: '100%' }}>
                <TabContext value={value}>
                    <TabList className="tabs-grid"
                        onChange={handleChange}
                        aria-label="lab API tabs example">
                        {viewTabs.map((e, i) => (
                            <Tab key={i} className="tab-button" label={e.label} value={e.value} />
                        ))}
                    </TabList>
                </TabContext>
            </Grid>
            {Object.values(rel_Data)?.length > 0 ? [nifty50Data, bankNiftyData, itNiftyData, finNiftyData, midcapSelectData, rel_Data].map((s, i) => {
               const traderViewPercentages = [s?.daily?.length > 0 ? s?.daily[0]?.percentage?.toFixed(2) : 0, s?.weekly.length > 0 ? s?.weekly[0]?.percentage.toFixed(2) : 0, s?.monthly?.length > 0 ? s?.monthly[0]?.percentage.toFixed(2) : 0]
                const traderOpen = [s?.daily[0]?.current_open ? s?.daily[0]?.current_open : s?.daily[0]?.ticker_open, s?.weekly[0]?.current_open ? s?.weekly[0]?.current_open : s?.weekly[0]?.ticker_open, s?.monthly[0]?.current_open ? s?.monthly[0]?.current_open : s?.monthly[0]?.ticker_open]

                const tradaHigh = [s?.daily[0]?.current_high >= s?.daily[0]?.ticker_high ? s?.daily[0]?.current_high : s?.daily[0]?.ticker_high, s?.weekly[0]?.current_high >= s?.weekly[0]?.ticker_high ? s?.weekly[0]?.current_high : s?.weekly[0]?.ticker_high, s?.monthly[0]?.current_high >= s?.monthly[0]?.ticker_high ? s?.monthly[0]?.current_high : s?.monthly[0]?.ticker_high]

                const traderLow = [s?.daily[0]?.current_low >= s?.daily[0]?.ticker_low ? s?.daily[0]?.ticker_low : s?.daily[0]?.current_low == 0 ? s?.daily[0]?.ticker_low : s?.daily[0]?.current_low, s?.weekly[0]?.current_low >= s?.weekly[0]?.ticker_low ? s?.weekly[0]?.ticker_low : s?.weekly[0]?.current_low == 0 ? s?.weekly[0]?.ticker_low : s?.weekly[0]?.current_low, s?.monthly[0]?.current_low >= s?.monthly[0]?.ticker_low ? s?.monthly[0]?.ticker_low : s?.monthly[0]?.current_low == 0 ? s?.monthly[0]?.ticker_low : s?.monthly[0]?.current_low]

                const tradaClose = [s?.daily[0]?.current_close, s?.weekly[0]?.current_close, s?.monthly[0]?.current_close]

                const tradaRange = [s?.daily[0]?.pre_percentage, s?.weekly[0]?.pre_percentage, s?.monthly[0]?.pre_percentage]

                const previousclose =[s?.daily[0]?.pre_close, s?.weekly[0]?.pre_close, s?.monthly[0]?.pre_close]

                const investorViewPercentages = [s?.quarterly?.length > 0 ? s?.quarterly[0]?.percentage.toFixed(2) : 0, s?.halfYearly.length > 0 ? s?.halfYearly[0]?.percentage.toFixed(2) : 0, s?.yearly?.length > 0 ? s?.yearly[0]?.percentage.toFixed(2) : 0]
                
                const investorOpen = [s?.quarterly[0]?.current_open ? s?.quarterly[0]?.current_open : s?.quarterly[0]?.ticker_open, s?.halfYearly[0]?.current_open ? s?.halfYearly[0]?.current_open : s?.halfYearly[0]?.ticker_open, s?.yearly[0]?.current_open ? s?.yearly[0]?.current_open : s?.yearly[0]?.ticker_open]

                const investorHigh = [s?.quarterly[0]?.current_high >= s?.quarterly[0]?.ticker_high ? s?.quarterly[0]?.current_high : s?.quarterly[0]?.ticker_high, s?.halfYearly[0]?.current_high >= s?.halfYearly[0]?.ticker_high ? s?.halfYearly[0]?.current_high : s?.halfYearly[0]?.ticker_high, s?.yearly[0]?.current_high >= s?.yearly[0]?.ticker_high ? s?.yearly[0]?.current_high : s?.yearly[0]?.ticker_high]

                const investorLow = [s?.quarterly[0]?.current_low >= s?.quarterly[0]?.ticker_low ? s?.quarterly[0]?.ticker_low : s?.quarterly[0]?.current_low == 0 ? s?.quarterly[0]?.ticker_low : s?.quarterly[0]?.current_low, s?.halfYearly[0]?.current_low >= s?.halfYearly[0]?.ticker_low ? s?.halfYearly[0]?.ticker_low : s?.halfYearly[0]?.current_low == 0 ? s?.halfYearly[0]?.ticker_low : s?.halfYearly[0]?.current_low, s?.yearly[0]?.current_low >= s?.yearly[0]?.ticker_low ? s?.yearly[0]?.ticker_low : s?.yearly[0]?.current_low == 0 ? s?.yearly[0]?.ticker_low : s?.yearly[0]?.current_low]

                const investorClose = [s?.quarterly[0]?.current_close, s?.halfYearly[0]?.current_close, s?.yearly[0]?.current_close]

                const investorRange = [s?.quarterly[0]?.pre_percentage, s?.halfYearly[0]?.pre_percentage, s?.yearly[0]?.pre_percentage]
                const previousclose_i =[s?.quarterly[0]?.pre_close, s?.halfYearly[0]?.pre_close, s?.yearly[0]?.pre_close]

                return (
                    <>
                        <Grid className='w-1/6 flex flex-col'>
                            {value == '1' && <Grid className='w-full border-timeframe plusplus-border p-0'>
                                <Grid className='header-timeframe'>
                                    <Typography align='center' className='font-semibold font-futura' style={{ fontSize: '14px' }}>{getTF(i, true)} ( {s?.daily[0]?.currentprice} )</Typography>
                                </Grid>
                                <Grid className=''>
                                    <GaugeChartTF tf={["Daily", "Weekly", "Monthly"]}  previousclose={previousclose} tradaRange={tradaRange} tradaClose={tradaClose} tradaHigh={tradaHigh} traderOpen={traderOpen} traderLow={traderLow}  viewPercentages={traderViewPercentages} mean={((Number(traderViewPercentages[0]) + Number(traderViewPercentages[1]) + Number(traderViewPercentages[2])) / 3)?.toFixed(2)} cmp={s?.daily[0]?.currentprice}/>
                                    <Grid className='indice-candle-nsePlusePluse' style={{ backgroundColor: 'lightblue' }}>
                                        <Grid className="flex justify-center -mb-1" >
                                            <Typography variant='body2' className="font-black font-futura flex whitespace-no-wrap" align="center" style={{ fontSize: '12px', color: `#000066` }}>(D) - {getTF(i)}
                                                {/* <span className="flex items-center" style={{ fontSize: '12px', marginLeft: '3px' }}>{s?.daily[0]?.currentprice}</span> */}
                                                <span className="flex items-center" style={{ fontSize: '10px' }}>({(s?.daily[0]?.currentprice - s?.daily[0]?.pre_close).toFixed(1)})({getPerc(s?.daily[0]?.currentprice, s?.daily[0]?.pre_close)}%)</span>
                                            </Typography>
                                        </Grid>
                                        <Grid style={{ marginTop: '4px' }}>
                                            <MetricCandleStick open={s?.daily[0]?.current_open ? s?.daily[0]?.current_open : s?.daily[0]?.ticker_open}
                                                high={s?.daily[0]?.current_high >= s?.daily[0]?.ticker_high ? s?.daily[0]?.current_high : s?.daily[0]?.ticker_high}
                                                low={s?.daily[0]?.current_low >= s?.daily[0]?.ticker_low ? s?.daily[0]?.ticker_low : s?.daily[0]?.current_low == 0 ? s?.daily[0]?.ticker_low : s?.daily[0]?.current_low}
                                                close={s?.daily[0]?.current_close} poscount={s?.daily[0]?.poscount} negcount={s?.daily[0]?.negcount} arrow={s?.daily[0]?.arrow} totalMetrics={s?.daily[0]?.totalMetrics} prev_range={s?.daily[0]?.pre_percentage}
                                                percentage={s?.daily?.[0]?.percentage} />
                                        </Grid>
                                        <Grid className='im-ohlc flex w-full'>
                                            <Grid className="im-lc w-1/3">
                                                <Typography align="center" className="high-color">C.R: {((s?.daily[0]?.current_high >= s?.daily[0]?.ticker_high ? s?.daily[0]?.current_high : s?.daily[0]?.ticker_high) - (s?.daily[0]?.current_low >= s?.daily[0]?.ticker_low ? s?.daily[0]?.ticker_low : s?.daily[0]?.current_low == 0 ? s?.daily[0]?.ticker_low : s?.daily[0]?.current_low))?.toFixed(1)}</Typography>
                                                <Typography align="center" className={(s?.daily[0]?.pre_high - s?.daily[0]?.pre_low) > 0 ? "high-color" : "low-color"}>P.R: {Number(s?.daily[0]?.pre_low) ? (s?.daily[0]?.pre_high - s?.daily[0]?.pre_low)?.toFixed(1) : 0}</Typography>
                                            </Grid>
                                            <Grid className="im-oh w-1/3">
                                                <Typography align="center" className="high-color">O: {s?.daily[0]?.current_open == 0 ? s?.daily[0]?.ticker_open : s?.daily[0]?.current_open?.toFixed(1)}</Typography>
                                                <Typography align="center" className="high-color">P.C: {s?.daily[0]?.pre_close?.toFixed(1)}</Typography>
                                            </Grid>
                                            <Grid className="im-rp w-1/3">
                                                <Typography align="center" className="high-color">H: {s?.daily[0]?.current_high >= s?.daily[0]?.ticker_high ? s?.daily[0]?.current_high?.toFixed(1) : s?.daily[0]?.ticker_high?.toFixed(1)}</Typography>
                                                <Typography align="center" className="low-color">L: {s?.daily[0]?.current_low >= s?.daily[0]?.ticker_low ? s?.daily[0]?.ticker_low?.toFixed(1) : s?.daily[0]?.current_low == "0" ? s?.daily[0]?.ticker_low?.toFixed(1) : s?.daily[0]?.current_low?.toFixed(1)} </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid className='indice-candle-nsePlusePluse' style={{ backgroundColor: 'lightblue' }}>
                                        <Grid className="flex justify-center -mb-1">
                                            <Typography variant='body2' className="font-black font-futura flex whitespace-no-wrap" align="center" style={{ fontSize: '12px', color: `#000066` }}>(W) - {getTF(i)}
                                                {/* <span className="flex items-center" style={{ fontSize: '12px', marginLeft: '3px' }}>{s?.weekly[0]?.currentprice}</span> */}
                                                <span className="flex items-center" style={{ fontSize: '10px' }}>({(s?.weekly[0]?.currentprice - s?.weekly[0]?.pre_close).toFixed(1)})({getPerc(s?.weekly[0]?.currentprice, s?.weekly[0]?.pre_close)}%)</span>
                                            </Typography>
                                        </Grid>
                                        <Grid style={{ marginTop: '4px' }}>
                                            <MetricCandleStick open={s?.weekly[0]?.current_open ? s?.weekly[0]?.current_open : s?.weekly[0]?.ticker_open}
                                                high={s?.weekly[0]?.current_high >= s?.weekly[0]?.ticker_high ? s?.weekly[0]?.current_high : s?.weekly[0]?.ticker_high}
                                                low={s?.weekly[0]?.current_low >= s?.weekly[0]?.ticker_low ? s?.weekly[0]?.ticker_low : s?.weekly[0]?.current_low == 0 ? s?.weekly[0]?.ticker_low : s?.weekly[0]?.current_low}
                                                close={s?.weekly[0]?.current_close} poscount={s?.weekly[0]?.poscount} negcount={s?.weekly[0]?.negcount} arrow={s?.weekly[0]?.arrow} totalMetrics={s?.weekly[0]?.totalMetrics} prev_range={s?.weekly[0]?.pre_percentage}
                                                percentage={s?.weekly?.[0]?.percentage} />
                                        </Grid>
                                        <Grid className='im-ohlc flex w-full'>
                                            <Grid className="im-lc w-1/3">
                                                <Typography align="center" className="high-color">C.R: {((s?.weekly[0]?.current_high >= s?.weekly[0]?.ticker_high ? s?.weekly[0]?.current_high : s?.weekly[0]?.ticker_high) - (s?.weekly[0]?.current_low >= s?.weekly[0]?.ticker_low ? s?.weekly[0]?.ticker_low : s?.weekly[0]?.current_low == 0 ? s?.weekly[0]?.ticker_low : s?.weekly[0]?.current_low))?.toFixed(1)}</Typography>
                                                <Typography align="center" className={(s?.weekly[0]?.pre_high - s?.weekly[0]?.pre_low) > 0 ? "high-color" : "low-color"}>P.R: {Number(s?.weekly[0]?.pre_low) ? (s?.weekly[0]?.pre_high - s?.weekly[0]?.pre_low)?.toFixed(1) : 0}</Typography>
                                            </Grid>
                                            <Grid className="im-oh w-1/3">
                                                <Typography align="center" className="high-color">O: {s?.weekly[0]?.current_open == 0 ? s?.weekly[0]?.ticker_open : s?.weekly[0]?.current_open?.toFixed(1)}</Typography>
                                                <Typography align="center" className="high-color">P.C: {s?.weekly[0]?.pre_close?.toFixed(1)}</Typography>
                                            </Grid>
                                            <Grid className="im-rp w-1/3">
                                                <Typography align="center" className="high-color">H: {s?.weekly[0]?.current_high >= s?.weekly[0]?.ticker_high ? s?.weekly[0]?.current_high?.toFixed(1) : s?.weekly[0]?.ticker_high?.toFixed(1)}</Typography>
                                                <Typography align="center" className="low-color">L: {s?.weekly[0]?.current_low >= s?.weekly[0]?.ticker_low ? s?.weekly[0]?.ticker_low?.toFixed(1) : s?.weekly[0]?.current_low == "0" ? s?.weekly[0]?.ticker_low?.toFixed(1) : s?.weekly[0]?.current_low?.toFixed(1)} </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid className='indice-candle-nsePlusePluse' style={{ backgroundColor: 'lightblue' }}>
                                        <Grid className="flex justify-center -mb-1">
                                            <Typography variant='body2' className="font-black font-futura flex whitespace-no-wrap" align="center" style={{ fontSize: '12px', color: `#000066` }}>(M) - {getTF(i)}
                                                {/* <span className="flex items-center" style={{ fontSize: '12px', marginLeft: '3px' }}>{s?.monthly[0]?.currentprice}</span> */}
                                                <span className="flex items-center" style={{ fontSize: '10px' }}>({(s?.monthly[0]?.currentprice - s?.monthly[0]?.pre_close).toFixed(1)})({getPerc(s?.monthly[0]?.currentprice, s?.monthly[0]?.pre_close)}%)</span>
                                            </Typography>
                                        </Grid>
                                        <Grid style={{ marginTop: '4px' }}>
                                            <MetricCandleStick open={s?.monthly[0]?.current_open ? s?.monthly[0]?.current_open : s?.monthly[0]?.ticker_open}
                                                high={s?.monthly[0]?.current_high >= s?.monthly[0]?.ticker_high ? s?.monthly[0]?.current_high : s?.monthly[0]?.ticker_high}
                                                low={s?.monthly[0]?.current_low >= s?.monthly[0]?.ticker_low ? s?.monthly[0]?.ticker_low : s?.monthly[0]?.current_low == 0 ? s?.monthly[0]?.ticker_low : s?.monthly[0]?.current_low}
                                                close={s?.monthly[0]?.current_close} poscount={s?.monthly[0]?.poscount} negcount={s?.monthly[0]?.negcount} arrow={s?.monthly[0]?.arrow} totalMetrics={s?.monthly[0]?.totalMetrics} prev_range={s?.monthly[0]?.pre_percentage}
                                                percentage={s?.monthly?.[0]?.percentage} />
                                        </Grid>
                                        <Grid className='im-ohlc flex w-full'>
                                            <Grid className="im-lc w-1/3">
                                                <Typography align="center" className="high-color">C.R: {((s?.monthly[0]?.current_high >= s?.monthly[0]?.ticker_high ? s?.monthly[0]?.current_high : s?.monthly[0]?.ticker_high) - (s?.monthly[0]?.current_low >= s?.monthly[0]?.ticker_low ? s?.monthly[0]?.ticker_low : s?.monthly[0]?.current_low == 0 ? s?.monthly[0]?.ticker_low : s?.monthly[0]?.current_low))?.toFixed(1)}</Typography>
                                                <Typography align="center" className={(s?.monthly[0]?.pre_high - s?.monthly[0]?.pre_low) > 0 ? "high-color" : "low-color"}>P.R: {Number(s?.monthly[0]?.pre_low) ? (s?.monthly[0]?.pre_high - s?.monthly[0]?.pre_low)?.toFixed(1) : 0}</Typography>
                                            </Grid>
                                            <Grid className="im-oh w-1/3">
                                                <Typography align="center" className="high-color">O: {s?.monthly[0]?.current_open == 0 ? s?.monthly[0]?.ticker_open : s?.monthly[0]?.current_open?.toFixed(1)}</Typography>
                                                <Typography align="center" className="high-color">P.C: {s?.monthly[0]?.pre_close?.toFixed(1)}</Typography>
                                            </Grid>
                                            <Grid className="im-rp w-1/3">
                                                <Typography align="center" className="high-color">H: {s?.monthly[0]?.current_high >= s?.monthly[0]?.ticker_high ? s?.monthly[0]?.current_high?.toFixed(1) : s?.monthly[0]?.ticker_high?.toFixed(1)}</Typography>
                                                <Typography align="center" className="low-color">L: {s?.monthly[0]?.current_low >= s?.monthly[0]?.ticker_low ? s?.monthly[0]?.ticker_low?.toFixed(1) : s?.monthly[0]?.current_low == "0" ? s?.monthly[0]?.ticker_low?.toFixed(1) : s?.monthly[0]?.current_low?.toFixed(1)} </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>}
                            {value == '2' && <Grid className='w-full border-timeframe plusplus-border p-0 mt-1'>
                                <Grid className='header-timeframe'>
                                    <Typography align='center' className='font-semibold font-futura' style={{ fontSize: '14px' }}>{getTF(i, true)} ( {s?.quarterly[0]?.currentprice} )</Typography>
                                </Grid>
                                <Grid className=''>
                                    <GaugeChartTF  tf={["Quarterly", "HalfYearly", "Yearly"]} cmp={s?.daily[0]?.currentprice} previousclose={previousclose_i} tradaRange={investorRange} tradaClose={investorClose} tradaHigh={investorHigh} traderOpen={investorOpen} traderLow={investorLow} viewPercentages={investorViewPercentages} mean={((Number(investorViewPercentages[0]) + Number(investorViewPercentages[1]) + Number(investorViewPercentages[2])) / 3)?.toFixed(2)} />
                                    <Grid className='indice-candle-nsePlusePluse' style={{ backgroundColor: 'lightblue' }}>
                                        <Grid className="flex justify-center -mb-1">
                                            <Typography variant='body2' className="font-black font-futura flex whitespace-no-wrap" align="center" style={{ fontSize: '12px', color: `#000066` }}>(Q) - {getTF(i)}
                                                {/* <span className="flex items-center" style={{ fontSize: '12px', marginLeft: '3px' }}>{s?.quarterly[0]?.currentprice}</span> */}
                                                <span className="flex items-center" style={{ fontSize: '10px' }}>({(s?.quarterly[0]?.currentprice - s?.quarterly[0]?.pre_close).toFixed(1)})({getPerc(s?.quarterly[0]?.currentprice, s?.quarterly[0]?.pre_close)}%)</span>
                                            </Typography>
                                        </Grid>
                                        <MetricCandleStick open={s?.quarterly[0]?.current_open ? s?.quarterly[0]?.current_open : s?.quarterly[0]?.ticker_open}
                                            high={s?.quarterly[0]?.current_high >= s?.quarterly[0]?.ticker_high ? s?.quarterly[0]?.current_high : s?.quarterly[0]?.ticker_high}
                                            low={s?.quarterly[0]?.current_low >= s?.quarterly[0]?.ticker_low ? s?.quarterly[0]?.ticker_low : s?.quarterly[0]?.current_low == 0 ? s?.quarterly[0]?.ticker_low : s?.quarterly[0]?.current_low}
                                            close={s?.quarterly[0]?.current_close} poscount={s?.quarterly[0]?.poscount} negcount={s?.quarterly[0]?.negcount} arrow={s?.quarterly[0]?.arrow} totalMetrics={s?.quarterly[0]?.totalMetrics} prev_range={s?.quarterly[0]?.pre_percentage}
                                            percentage={s?.quarterly?.[0]?.percentage} />
                                        <Grid className='im-ohlc flex w-full'>
                                            <Grid className="im-lc w-1/3">
                                                <Typography align="center" className="high-color">C.R: {((s?.quarterly[0]?.current_high >= s?.quarterly[0]?.ticker_high ? s?.quarterly[0]?.current_high : s?.quarterly[0]?.ticker_high) - (s?.quarterly[0]?.current_low >= s?.quarterly[0]?.ticker_low ? s?.quarterly[0]?.ticker_low : s?.quarterly[0]?.current_low == 0 ? s?.quarterly[0]?.ticker_low : s?.quarterly[0]?.current_low))?.toFixed(1)}</Typography>
                                                <Typography align="center" className={(s?.quarterly[0]?.pre_high - s?.quarterly[0]?.pre_low) > 0 ? "high-color" : "low-color"}>P.R: {Number(s?.quarterly[0]?.pre_low) ? (s?.quarterly[0]?.pre_high - s?.quarterly[0]?.pre_low)?.toFixed(1) : 0}</Typography>
                                            </Grid>
                                            <Grid className="im-oh w-1/3">
                                                <Typography align="center" className="high-color">O: {s?.quarterly[0]?.current_open == 0 ? s?.quarterly[0]?.ticker_open : s?.quarterly[0]?.current_open?.toFixed(1)}</Typography>
                                                <Typography align="center" className="high-color">P.C: {s?.quarterly[0]?.pre_close?.toFixed(1)}</Typography>
                                            </Grid>
                                            <Grid className="im-rp w-1/3">
                                                <Typography align="center" className="high-color">H: {s?.quarterly[0]?.current_high >= s?.quarterly[0]?.ticker_high ? s?.quarterly[0]?.current_high?.toFixed(1) : s?.quarterly[0]?.ticker_high?.toFixed(1)}</Typography>
                                                <Typography align="center" className="low-color">L: {s?.quarterly[0]?.current_low >= s?.quarterly[0]?.ticker_low ? s?.quarterly[0]?.ticker_low?.toFixed(1) : s?.quarterly[0]?.current_low == "0" ? s?.quarterly[0]?.ticker_low?.toFixed(1) : s?.quarterly[0]?.current_low?.toFixed(1)} </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid className='indice-candle-nsePlusePluse' style={{ backgroundColor: 'lightblue' }}>
                                        <Grid className="flex justify-center -mb-1">
                                            <Typography variant='body2' className="font-black font-futura flex whitespace-no-wrap" align="center" style={{ fontSize: '12px', color: `#000066` }}>(HY) - {getTF(i)}
                                                {/* <span className="flex items-center" style={{ fontSize: '12px', marginLeft: '3px' }}>{s?.halfYearly[0]?.currentprice}</span> */}
                                                <span className="flex items-center" style={{ fontSize: '10px' }}>({(s?.halfYearly[0]?.currentprice - s?.halfYearly[0]?.pre_close).toFixed(1)})({getPerc(s?.halfYearly[0]?.currentprice, s?.halfYearly[0]?.pre_close)}%)</span>
                                            </Typography>
                                        </Grid>
                                        <MetricCandleStick open={s?.halfYearly[0]?.current_open ? s?.halfYearly[0]?.current_open : s?.halfYearly[0]?.ticker_open}
                                            high={s?.halfYearly[0]?.current_high >= s?.halfYearly[0]?.ticker_high ? s?.halfYearly[0]?.current_high : s?.halfYearly[0]?.ticker_high}
                                            low={s?.halfYearly[0]?.current_low >= s?.halfYearly[0]?.ticker_low ? s?.halfYearly[0]?.ticker_low : s?.halfYearly[0]?.current_low == 0 ? s?.halfYearly[0]?.ticker_low : s?.halfYearly[0]?.current_low}
                                            close={s?.halfYearly[0]?.current_close} poscount={s?.halfYearly[0]?.poscount} negcount={s?.halfYearly[0]?.negcount} arrow={s?.halfYearly[0]?.arrow} totalMetrics={s?.halfYearly[0]?.totalMetrics} prev_range={s?.halfYearly[0]?.pre_percentage}
                                            percentage={s?.halfYearly?.[0]?.percentage} />
                                        <Grid className='im-ohlc flex w-full'>
                                            <Grid className="im-lc w-1/3">
                                                <Typography align="center" className="high-color">C.R: {((s?.halfYearly[0]?.current_high >= s?.halfYearly[0]?.ticker_high ? s?.halfYearly[0]?.current_high : s?.halfYearly[0]?.ticker_high) - (s?.halfYearly[0]?.current_low >= s?.halfYearly[0]?.ticker_low ? s?.halfYearly[0]?.ticker_low : s?.halfYearly[0]?.current_low == 0 ? s?.halfYearly[0]?.ticker_low : s?.halfYearly[0]?.current_low))?.toFixed(1)}</Typography>
                                                <Typography align="center" className={(s?.halfYearly[0]?.pre_high - s?.halfYearly[0]?.pre_low) > 0 ? "high-color" : "low-color"}>P.R: {Number(s?.halfYearly[0]?.pre_low) ? (s?.halfYearly[0]?.pre_high - s?.halfYearly[0]?.pre_low)?.toFixed(1) : 0}</Typography>
                                            </Grid>
                                            <Grid className="im-oh w-1/3">
                                                <Typography align="center" className="high-color">O: {s?.halfYearly[0]?.current_open == 0 ? s?.halfYearly[0]?.ticker_open : s?.halfYearly[0]?.current_open?.toFixed(1)}</Typography>
                                                <Typography align="center" className="high-color">P.C: {s?.halfYearly[0]?.pre_close?.toFixed(1)}</Typography>
                                            </Grid>
                                            <Grid className="im-rp w-1/3">
                                                <Typography align="center" className="high-color">H: {s?.halfYearly[0]?.current_high >= s?.halfYearly[0]?.ticker_high ? s?.halfYearly[0]?.current_high?.toFixed(1) : s?.halfYearly[0]?.ticker_high?.toFixed(1)}</Typography>
                                                <Typography align="center" className="low-color">L: {s?.halfYearly[0]?.current_low >= s?.halfYearly[0]?.ticker_low ? s?.halfYearly[0]?.ticker_low?.toFixed(1) : s?.halfYearly[0]?.current_low == "0" ? s?.halfYearly[0]?.ticker_low?.toFixed(1) : s?.halfYearly[0]?.current_low?.toFixed(1)} </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid className='indice-candle-nsePlusePluse' style={{ backgroundColor: 'lightblue' }}>
                                        <Grid className="flex justify-center -mb-1">
                                            <Typography variant='body2' className="font-black font-futura flex whitespace-no-wrap" align="center" style={{ fontSize: '12px', color: `#000066` }}>(Y) - {getTF(i)}
                                                {/* <span className="flex items-center" style={{ fontSize: '12px', marginLeft: '3px' }}>{s?.yearly[0]?.currentprice}</span> */}
                                                <span className="flex items-center" style={{ fontSize: '10px' }}>({(s?.yearly[0]?.currentprice - s?.yearly[0]?.pre_close).toFixed(1)})({getPerc(s?.yearly[0]?.currentprice, s?.yearly[0]?.pre_close)}%)</span>
                                            </Typography>
                                        </Grid>
                                        <MetricCandleStick open={s?.yearly[0]?.current_open ? s?.yearly[0]?.current_open : s?.yearly[0]?.ticker_open}
                                            high={s?.yearly[0]?.current_high >= s?.yearly[0]?.ticker_high ? s?.yearly[0]?.current_high : s?.yearly[0]?.ticker_high}
                                            low={s?.yearly[0]?.current_low >= s?.yearly[0]?.ticker_low ? s?.yearly[0]?.ticker_low : s?.yearly[0]?.current_low == 0 ? s?.yearly[0]?.ticker_low : s?.yearly[0]?.current_low}
                                            close={s?.yearly[0]?.current_close} poscount={s?.yearly[0]?.poscount} negcount={s?.yearly[0]?.negcount} arrow={s?.yearly[0]?.arrow} totalMetrics={s?.yearly[0]?.totalMetrics} prev_range={s?.yearly[0]?.pre_percentage}
                                            percentage={s?.yearly?.[0]?.percentage} />
                                        <Grid className='im-ohlc flex w-full'>
                                            <Grid className="im-lc w-1/3">
                                                <Typography align="center" className="high-color">C.R: {((s?.yearly[0]?.current_high >= s?.yearly[0]?.ticker_high ? s?.yearly[0]?.current_high : s?.yearly[0]?.ticker_high) - (s?.yearly[0]?.current_low >= s?.yearly[0]?.ticker_low ? s?.yearly[0]?.ticker_low : s?.yearly[0]?.current_low == 0 ? s?.yearly[0]?.ticker_low : s?.yearly[0]?.current_low))?.toFixed(1)}</Typography>
                                                <Typography align="center" className={(s?.yearly[0]?.pre_high - s?.yearly[0]?.pre_low) > 0 ? "high-color" : "low-color"}>P.R: {Number(s?.yearly[0]?.pre_low) ? (s?.yearly[0]?.pre_high - s?.yearly[0]?.pre_low)?.toFixed(1) : 0}</Typography>
                                            </Grid>
                                            <Grid className="im-oh w-1/3">
                                                <Typography align="center" className="high-color">O: {s?.yearly[0]?.current_open == 0 ? s?.yearly[0]?.ticker_open : s?.yearly[0]?.current_open?.toFixed(1)}</Typography>
                                                <Typography align="center" className="high-color">P.C: {s?.yearly[0]?.pre_close?.toFixed(1)}</Typography>
                                            </Grid>
                                            <Grid className="im-rp w-1/3">
                                                <Typography align="center" className="high-color">H: {s?.yearly[0]?.current_high >= s?.yearly[0]?.ticker_high ? s?.yearly[0]?.current_high?.toFixed(1) : s?.yearly[0]?.ticker_high?.toFixed(1)}</Typography>
                                                <Typography align="center" className="low-color">L: {s?.yearly[0]?.current_low >= s?.yearly[0]?.ticker_low ? s?.yearly[0]?.ticker_low?.toFixed(1) : s?.yearly[0]?.current_low == "0" ? s?.yearly[0]?.ticker_low?.toFixed(1) : s?.yearly[0]?.current_low?.toFixed(1)} </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>}
                        </Grid>
                    </>
                )
            }) : <Typography align='center' className='font-semibold font-futura'>Loading...</Typography>}
        </Grid>
    )
}

export default NSEPlusPlus