// import { Button, Drawer, Grid, MenuItem } from "@material-ui/core";
// import React, { useState } from "react";
// import AppIcon, { AppLogo } from "../icons/AppIcon";
// import MenuIcon from "@material-ui/icons/Menu";
// import useMediaQuery from "@material-ui/core/useMediaQuery";
// import CloseIcon from "@material-ui/icons/Close";
// import { useHistory } from "react-router-dom";
// import "./header.css";
// import Login from "../Login/Login";
// import Signup from "../Signup/Signup";

// const LandingHeader = (props = {}) => {

//   const history = useHistory();

//   const [showSidebar, setShowSidebar] = useState(false);
//   const [showLogin, setShowLogin] = useState(false);
//   const [showSignUp, setShowSignUp] = useState(false);
//   const matches = useMediaQuery("(min-width:600px)");
//   const handleMenuClick = () => {
//     setShowSidebar(true);
//   };
//   return (
//     <Grid container id="header" alignItems="center" style={{ position: "fixed", top: 0, paddingTop:'4px' }}
//       className="header bg-white flex justify-between"
//     >
//       {!matches && (
//         <Grid style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
//           <Button className="menu-icon ml-2" onClick={handleMenuClick}>
//             <MenuIcon />
//             <AppLogo />
//           </Button>
//           <Grid>
//           <Grid className='me-2'>
//             <Grid  style={{display:"flex"}}>
//               <Button className="mr-2"  onClick={() => setShowSignUp(true)}>Sign up</Button>
//               <Login showLogin={showLogin} setShowLogin={setShowLogin} changeToSignUp={() => {
//                 setShowLogin(false)
//                 setShowSignUp(true)
//               }}/>
//             </Grid>
//           </Grid>
//           <Grid className='me-2'>
//             <Signup showSignUp={showSignUp} setShowSignUp={setShowSignUp} changeToLogin={() => {
//               setShowSignUp(false)
//               setShowLogin(true)
//             }}/>
//           </Grid>
//           </Grid>
//         </Grid>
//       )}
//       {matches && (
//         <Grid  id="nav-links" style={{ display: 'flex', width: '100%', alignItems : "center",justifyContent: 'space-around', paddingTop:'8px' }}>
//           <Grid >
//             <AppLogo />
//           </Grid>
//           <Grid style={{display : "flex", justifyContent : "space-evenly",width : "35%"}}>
//             {/* <Grid item container justifyContent="center"> */}
//               <Button href="#hero" 
//                 // className=" text-black font-medium  nav-link-btn"
//               >
//                 Home
//               </Button>
//               <Button href="#about" 
//                 // className=" text-black font-medium  nav-link-btn flex items-center"
//               >
//                 About
//               </Button>
//               <Button href="#services" 
//                 // className=" text-black font-medium"
//               >
//                 Services
//               </Button>
//               <Button href="#Contact" 
//                 // className=" text-black font-medium"
//               >
//                 CONTACT
//               </Button>
//             {/* </Grid> */}
//           </Grid>
//           <Grid className='m-2'>
//             <Grid  style={{display:"flex"}}>
//               <Button className="mr-2"  onClick={() => setShowSignUp(true)}>Sign up</Button>
//               <Login showLogin={showLogin} setShowLogin={setShowLogin} changeToSignUp={() => {
//                 setShowLogin(false)
//                 setShowSignUp(true)
//               }}/>
//               <Signup showSignUp={showSignUp} setShowSignUp={setShowSignUp} changeToLogin={() => {
//               setShowSignUp(false)
//               setShowLogin(true)
//             }}/>
//             </Grid>
//           </Grid>
//         </Grid>
//       )}
//       <Drawer
//         variant="temporary"
//         open={showSidebar}
//       // anchor={'right'}
//       >
//         <Grid sm={6} md={12}>
//           <div
//             className="flex justify-end"
//             style={{ width: "250px", margin: "10px" }}
//           >
//             <CloseIcon
//               onClick={() => {
//                 setShowSidebar(false);
//               }}
//             />
//           </div>
//           <Grid className="sideNav">
//             {!matches && (
//               <>
//                 <Button
//                   className=" text-black font-medium mr-4 nav-link-btn"
//                   href="#hero"
//                   onClick={() => setShowSidebar(false)}
//                   style={{ marginBottom: 11 }}
//                 >
//                   Home
//                 </Button>
//                 <Button
//                   className=" text-black font-medium mr-4 nav-link-btn flex items-center"
//                   href="#about"
//                   onClick={() => setShowSidebar(false)}
//                   style={{ marginBottom: 11 }}
//                 >
//                   About
//                 </Button>
//                 <Button
//                   className=" text-black font-medium mr-4"
//                   href="#services"
//                   style={{ marginBottom: 11 }}
//                   onClick={() => setShowSidebar(false)}
//                 >
//                   Services
//                 </Button>
//                 <Button
//                   className=" text-black font-medium"
//                   style={{ marginBottom: 11 }}
//                   href="#contact"
//                   onClick={() => setShowSidebar(false)}
//                 >
//                   CONTACT
//                 </Button>
//                 {/* <div onClick={() => setShowSidebar(false)}>
//                   <Login />
//                 </div> */}
//                 {/* <Button
//               className=" text-black font-medium"
//               style={{ marginBottom: 11 }}
//               onClick={() => {
//                 history.push('/login');
//               }}
//             >
//               Login
//             </Button> */}
//               </>
//             )}
//           </Grid>
//         </Grid>
//       </Drawer>
//     </Grid>
//   );
// };

// export default LandingHeader;
import { Button, Drawer, Grid } from "@material-ui/core";
import React, { useState } from "react";
import AppIcon, { AppLogo } from "../icons/AppIcon";
import MenuIcon from "@material-ui/icons/Menu";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router-dom";
import "./header.css";
import Login from "../Login/Login";
import Signup from "../Signup/Signup";

// const LandingHeader = (props = {}) => {
//   const history = useHistory();
//   const [showSidebar, setShowSidebar] = useState(false);
//   const [showLogin, setShowLogin] = useState(false);
//   const [showSignUp, setShowSignUp] = useState(false);
//   const matches = useMediaQuery("(min-width:600px)");

//   const handleMenuClick = () => {
//     setShowSidebar(true);
//   };

//   const handleNavigate = (path) => {
//     history.push(path);
//     setShowSidebar(false); // Optionally close the sidebar after navigation
//   };

//   return (
//     <Grid container id="header" alignItems="center" style={{ position: "fixed", top: 0, paddingTop: '4px' }}
//       className="header bg-white flex justify-between"
//     >
//       {!matches && (
//         <Grid style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
//           <Button className="menu-icon ml-2" onClick={handleMenuClick}>
//             <MenuIcon />
//             <AppLogo />
//           </Button>
//           <Grid>
//             <Grid className='me-2'>
//               <Grid style={{ display: "flex" }}>
//                 <Button className="mr-2" onClick={() => setShowSignUp(true)}>Sign up</Button>
//                 <Login showLogin={showLogin} setShowLogin={setShowLogin} changeToSignUp={() => {
//                   setShowLogin(false);
//                   setShowSignUp(true);
//                 }} />
//               </Grid>
//             </Grid>
//             <Grid className='me-2'>
//               <Signup showSignUp={showSignUp} setShowSignUp={setShowSignUp} changeToLogin={() => {
//                 setShowSignUp(false);
//                 setShowLogin(true);
//               }} />
//             </Grid>
//           </Grid>
//         </Grid>
//       )}
//       {matches && (
//         <Grid id="nav-links" style={{ display: 'flex', width: '100%', alignItems: "center", justifyContent: 'space-around', paddingTop: '8px' }}>
//           <Grid>
//             <AppLogo />
//           </Grid>
//           <Grid style={{ display: "flex", justifyContent: "space-evenly", width: "35%" }}>
//             <Button onClick={() => history.push("/")}>Home</Button>
//             <Button onClick={() => history.push("/service")}>Services</Button>
//             <Button onClick={() => history.push("/about")}>About</Button>
//             <Button onClick={() => history.push("/")}>CONTACT</Button>
//           </Grid>
//           <Grid className='m-2'>
//             <Grid style={{ display: "flex" }}>
//               <Button className="mr-2" onClick={() => setShowSignUp(true)}>Sign up</Button>
//               <Login showLogin={showLogin} setShowLogin={setShowLogin} changeToSignUp={() => {
//                 setShowLogin(false);
//                 setShowSignUp(true);
//               }} />
//               <Signup showSignUp={showSignUp} setShowSignUp={setShowSignUp} changeToLogin={() => {
//                 setShowSignUp(false);
//                 setShowLogin(true);
//               }} />
//             </Grid>
//           </Grid>
//         </Grid>
//       )}
//       <Drawer
//         variant="temporary"
//         open={showSidebar}
//       >
//         <Grid sm={6} md={12}>
//           <div
//             className="flex justify-end"
//             style={{ width: "250px", margin: "10px" }}
//           >
//             <CloseIcon
//               onClick={() => {
//                 setShowSidebar(false);
//               }}
//             />
//           </div>
//           <Grid className="sideNav">
//             {!matches && (
//               <>
//                 <Button
//                   className="text-black font-medium mr-4 nav-link-btn"
//                   onClick={() => handleNavigate("#hero")}
//                   style={{ marginBottom: 11 }}
//                 >
//                   Home
//                 </Button>
//                 <Button
//                   className="text-black font-medium mr-4 nav-link-btn"
//                   onClick={() => handleNavigate("#about")}
//                   style={{ marginBottom: 11 }}
//                 >
//                   About
//                 </Button>
//                 <Button
//                   className="text-black font-medium mr-4"
//                   onClick={() => handleNavigate("services")}
//                   style={{ marginBottom: 11 }}
//                 >
//                   Services
//                 </Button>
//                 <Button
//                   className="text-black font-medium"
//                   onClick={() => handleNavigate("#contact")}
//                   style={{ marginBottom: 11 }}
//                 >
//                   CONTACT
//                 </Button>
//               </>
//             )}
//           </Grid>
//         </Grid>
//       </Drawer>
//     </Grid>
//   );
// };

const LandingHeader = (props = {}) => {
  const history = useHistory();
  const [showSidebar, setShowSidebar] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [activeTab, setActiveTab] = useState('/');
  const matches = useMediaQuery("(min-width:600px)");

  const handleMenuClick = () => {
    setShowSidebar(true);
  };

  const handleNavigate = (path) => {
    history.push(path);
    window.scrollTo({ top: 0, behavior: 'smooth' });  // Scroll to top with smooth behavior
    setShowSidebar(false);  // Optionally close the sidebar after navigation
    setActiveTab(path);
  };

  return (
    <Grid container id="header" alignItems="center" style={{ position: "fixed", top: 0, paddingTop: '4px' }}
      className="header bg-white flex justify-between main-width"
    >
      {!matches && (
        <Grid style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="main-width">
          <Button className="menu-icon ml-2" onClick={handleMenuClick}>
            <MenuIcon className="mr-4" />
            <AppLogo />
          </Button>
          {/* <Grid>
            <Grid className='me-2'>
              <Grid style={{ display: "flex" }}>
                <Button className="mr-2" onClick={() => setShowSignUp(true)}>Sign up</Button>
                <Login showLogin={showLogin} setShowLogin={setShowLogin} changeToSignUp={() => {
                  setShowLogin(false);
                  setShowSignUp(true);
                }} />
              </Grid>
            </Grid>
            <Grid className='me-2'>
              <Signup showSignUp={showSignUp} setShowSignUp={setShowSignUp} changeToLogin={() => {
                setShowSignUp(false);
                setShowLogin(true);
              }} />
            </Grid>
          </Grid> */}
        </Grid>
      )}
      {matches && (
        <Grid id="nav-links" style={{ display: 'flex', width: '100%', alignItems: "center", justifyContent: 'space-around', paddingTop: '8px' }}>
          <Grid>
            <AppLogo />
          </Grid>
          <Grid className="app-bar-buttons" style={{ display: "flex", justifyContent: "space-evenly", width: "35%" }}>
            {/* <Button   className={activeTab === "/" ? "active" : ""} onClick={() => handleNavigate("/")}>Home</Button> */}
            <Button onClick={() => handleNavigate("/")}>Home</Button>
            <Button onClick={() => handleNavigate("/about")}>About</Button>
            <Button onClick={() => handleNavigate("/service")}>Services</Button>
            <Button onClick={() => handleNavigate("/exploreus")}>Explore </Button>
            <Button onClick={() => handleNavigate("/blogs")}>Blogs</Button>
          </Grid>
          <Grid className='m-2'>
            <Grid style={{ display: "flex" }}>
              <Button className="mr-2" onClick={() => setShowSignUp(true)}>Sign up</Button>
              <Login showLogin={showLogin} setShowLogin={setShowLogin} changeToSignUp={() => {
                setShowLogin(false);
                setShowSignUp(true);
              }} />
              <Signup showSignUp={showSignUp} setShowSignUp={setShowSignUp} changeToLogin={() => {
                setShowSignUp(false);
                setShowLogin(true);
              }} />
            </Grid>
          </Grid>
        </Grid>
      )}
      <Drawer
        variant="temporary"
        open={showSidebar}
      >
        <Grid sm={6} md={12}>
          <div
            className="flex justify-end"
            style={{ width: "250px", margin: "10px" }}
          >
            <CloseIcon
              onClick={() => {
                setShowSidebar(false);
              }}
            />
          </div>
          <Grid className="sideNav">
            {!matches && (
              <>
                <Button
                  className="text-black font-medium mr-4 nav-link-btn"
                  onClick={() => handleNavigate("/#hero")}  // Ensure these paths are correct for your routes
                  style={{ marginBottom: 11 }}
                >
                  Home
                </Button>
                <Button
                  className="text-black font-medium mr-4 nav-link-btn"
                  onClick={() => handleNavigate("/about")}
                  style={{ marginBottom: 11 }}
                >
                  About
                </Button>
                <Button
                  className="text-black font-medium mr-4"
                  onClick={() => handleNavigate("/service")}
                  style={{ marginBottom: 11 }}
                >
                  Services
                </Button>
                <Button
                  className="text-black font-medium"
                  onClick={() => handleNavigate("/exploreus")}
                  style={{ marginBottom: 11 }}
                >
                  Explore
                </Button>
                {/* <Button
                  className="text-black font-medium"
                  onClick={() => handleNavigate("/blogs")}
                  style={{ marginBottom: 11 }}
                >
                  Blogs
                </Button> */}
                <Grid className='m-2'>
                  <Grid style={{ display: "grid", justifyContent: "center" }}>
                    <Button className="mr-2" onClick={() => setShowSignUp(true)}>Sign up</Button>
                    <Login showLogin={showLogin} setShowLogin={setShowLogin} changeToSignUp={() => {
                      setShowLogin(false);
                      setShowSignUp(true);
                    }} />
                    <Signup showSignUp={showSignUp} setShowSignUp={setShowSignUp} changeToLogin={() => {
                      setShowSignUp(false);
                      setShowLogin(true);
                    }} />
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Drawer>
    </Grid>
  );
};
export default LandingHeader;
