import { FormControlLabel, FormGroup, Grid, Switch } from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import CandleStickChart from '../common/CandleGraph'
import { companyApiData, companyFibonacciData } from '../apiService/tradaApiService'
import dayjs from 'dayjs'
import { candleGraphFor15min, NiftyPlusPlus } from '../apiService/tickerApiService'
import { Typography } from '@mui/material'
import CandleStickChart30min from '../common/CGfor30min'
import CandleStickChart1hr from '../common/CGfor1hr'
import { ArrowDownward, ArrowUpward, Height } from '@material-ui/icons'
import FRECandleStickChart from '../common/FRECandleGraph'
import FRECandleStickChart30min from '../common/FRECandleFor30Min'
import FRECandleStickChart1hr from '../common/FRECandle1Hr'
import MetricCandleStick from '../common/MetricCandleStick'
import CandleStickIndices from '../common/CandleStickIndices'
import GraphForCandleStickChart from '../common/GraphForCandleStickChart'


function IndiceCandleGraphs(props) {
    const { value, dataFromChild } = props
    const { token, companySymbol } = dataFromChild
    const [data15min, setData15min] = useState([])
    const [data30min, setData30min] = useState([])
    const [data1hr, setData1hr] = useState([])
    const [dataYaxis15min_d, setDataYaxis15min_d] = useState([])
    const [dataYaxis30min_w, setDataYaxis30min_w] = useState([])
    const [dataYaxis_m, setDataYaxis_m] = useState([])
    const [keys_d, setKeys_d] = useState([])
    const [keys_w, setKeys_w] = useState([])
    const [keys_m, setKeys_m] = useState([])
    const [p_p_data, setP_p_data] = useState({})
    const [speedoMeterData, setSpeedoData] = useState([])
    const [view, setView] = useState(false)

    const [freData, setFreData] = useState({})
    const [freDataYaxis15min_d, setFreDataYaxis15min_d] = useState([])
    const [freDataYaxis30min_w, setFreDataYaxis30min_w] = useState([])
    const [freDataYaxis_m, setFreDataYaxis_m] = useState([])
    const [freKeys_d, setFreKeys_d] = useState([])
    const [freKeys_w, setFreKeys_w] = useState([])
    const [freKeys_m, setFreKeys_m] = useState([])

    const getCanldesData = async (tok, Symbol) => {
        try {
            const pp_data = await companyApiData(Symbol);
            const currentDate = dayjs().format('YYYY-MM-DD');

            setP_p_data(pp_data?.data)
            setDataYaxis15min_d(Object.values(pp_data?.data?.dailyData || {}));
            setDataYaxis30min_w(Object.values(pp_data?.data?.weeklyData || {}));
            setDataYaxis_m(Object.values(pp_data?.data?.monthlyData || {}));
            setKeys_d(Object.keys(pp_data?.data?.dailyData || {}))
            setKeys_w(Object.keys(pp_data?.data?.weeklyData || {}))
            setKeys_m(Object.keys(pp_data?.data?.monthlyData || {}))

        } catch (error) {
            console.error("Error fetching or processing data:", error);
        }
    }

    const fetchDataForSpeedometer = async (token, companySymbol) => {
        const company_speedo_data = await NiftyPlusPlus(companySymbol, token)
        setSpeedoData(company_speedo_data?.data)
    }

    useEffect(() => {
        let intervalId;
        intervalId = setInterval(() => {

            if (token && companySymbol) {
                // getCanldesData(token, companySymbol)
                fetchDataForSpeedometer(token, companySymbol)
            }
        }, 5000);
        return () => {
            clearInterval(intervalId)
            setDataYaxis15min_d([])
            setDataYaxis30min_w([])
            setDataYaxis_m([])
        }
    }, [token, companySymbol])

    useMemo(() => {
        if (token && companySymbol) {
            // getCanldesData(token, companySymbol)
            fetchDataForSpeedometer(token, companySymbol)
        }
    }, [token, companySymbol])

    const getHold = (v) => {
        if (v <= 20) return "Bearish++"
        else if (v > 20 && v <= 40) return "Bearish+"
        else if (v > 40 && v <= 60) return "Neutral"
        else if (v > 60 && v <= 80) return "Bullish+"
        else if (v > 80) return "Bullish++"
    }
    const getArrow = (arrow) => {
        switch (arrow) {
            case "UP": return <ArrowUpward color="secondary" style={{ fontSize: "18px", color: "#339249" }} />
            case "DOWN": return <ArrowDownward style={{ fontSize: "18px", color: "#DA2424" }} />
            case "NORMAL": return <Height style={{ rotate: "90deg", color: " #b5812a" }} />
            default:
                break;
        }
    }
    const onViewClick = (e) => {
        setView(e.target.checked)
    }
    const getColor = (v) => {  
        if (v <= 20) return "#da2424"
        else if (v > 20 && v <= 40) return "#f74f4f"
        else if (v > 40 && v <= 60) return "#ffb200e3"
        else if (v > 60 && v <= 80) return "#44db67"
        else if (v > 80) return "#339249"
        
    }
    return (
        <>
            {/* <Grid style={{ margin: '2px 10px 2px 0px', display: 'flex', justifyContent: 'end' }}>
                <FormGroup className="switch-view" >
                    <FormControlLabel control={<Switch size="small" onClick={onViewClick} />} label="FRE Graph" />
                </FormGroup>
            </Grid> */}
            {speedoMeterData?.weekly?.length > 0 ?
                <>
                    <Grid className='w-full flex gap-1 h-full'>
                        <Grid className='h-full' style={{ width: "55%", position: 'relative' }}>
                            <Grid style={{ background: '#000', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                <Grid>  <Typography style={{ color: getColor(Object.values(speedoMeterData)?.length > 0 ? speedoMeterData.daily?.[0]?.percentage?.toFixed(2) : 0), marginLeft: "3px", alignItems: 'center', display: 'flex' }} className='font-futura'>
                                    <span style={{ fontFamily: 'Futura PT Heavy', color: '#fff', fontSize: '13px' }}>15 Mins &nbsp;</span>
                                    <span style={{ fontFamily: 'Futura PT Heavy', fontSize: "18px" }}> {getHold(Object.values(speedoMeterData)?.length > 0 ? speedoMeterData.daily?.[0]?.percentage : 0)}</span>
                                    <span style={{ fontSize: '13px', marginLeft: '5px' }}> ({Object.values(speedoMeterData)?.length > 0 ? speedoMeterData.daily?.[0]?.percentage?.toFixed(2) : 0}%)</span>
                                </Typography></Grid>
                                <Grid style={{ width: '50%', zIndex: '99', left: '25%' }}>
                                    <CandleStickIndices percentage={speedoMeterData?.daily?.[0]?.percentage} open={speedoMeterData?.daily?.[0]?.current_open ? speedoMeterData?.daily?.[0]?.current_open : speedoMeterData?.daily?.[0]?.ticker_open}
                                        high={speedoMeterData?.daily?.[0]?.current_high >= speedoMeterData?.daily?.[0]?.ticker_high ? speedoMeterData?.daily?.[0]?.current_high : speedoMeterData?.daily?.[0]?.ticker_high}
                                        low={speedoMeterData?.daily?.[0]?.current_low >= speedoMeterData?.daily?.[0]?.ticker_low ? speedoMeterData?.daily?.[0]?.ticker_low : speedoMeterData?.daily?.[0]?.current_low == 0 ? speedoMeterData?.daily?.[0]?.ticker_low : speedoMeterData?.daily?.[0]?.current_low}
                                        close={speedoMeterData?.daily?.[0]?.current_close} poscount={speedoMeterData?.daily?.[0]?.poscount} negcount={speedoMeterData?.daily?.[0]?.negcount} arrow={speedoMeterData?.daily?.[0]?.arrow} totalMetrics={speedoMeterData?.daily?.[0]?.totalMetrics} prev_range={speedoMeterData?.daily?.[0]?.pre_percentage}

                                    />
                                </Grid>
                            </Grid>
                            <GraphForCandleStickChart token={token} min="15" companySymbol={companySymbol} tf="dailyData" ht= "398"/>
                            {/* <CandleStickChart yAxisValues={dataYaxis15min_d} graphData={data15min} keys={keys_d} data={p_p_data?.dailyData} /> */}
                        </Grid>
                        <Grid className='' style={{ width: "23%", marginRight: '5px', position: 'relative' }}>
                            <Grid style={{ background: '#000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Typography style={{ color: getColor(Object.values(speedoMeterData)?.length > 0 ? speedoMeterData.weekly?.[0]?.percentage?.toFixed(2) : 0), marginLeft: "3px", alignItems: 'center', display: 'flex' }} className='font-futura'>
                                    <span style={{ fontFamily: 'Futura PT Heavy', color: '#fff', fontSize: '13px' }}>30 Mins &nbsp;</span>
                                    <span style={{ fontFamily: 'Futura PT Heavy', fontSize: "18px" }}> {getHold(Object.values(speedoMeterData)?.length > 0 ? speedoMeterData.weekly?.[0]?.percentage : 0)}</span>
                                    <span style={{ fontSize: '13px', marginLeft: '5px' }}>  ({Object.values(speedoMeterData)?.length > 0 ? speedoMeterData.weekly?.[0]?.percentage?.toFixed(2) : 0}%)</span>
                                </Typography>
                            </Grid>
                            <Grid style={{ position: 'absolute', width: '90%', zIndex: '99', left: '5%' }}>
                                <CandleStickIndices percentage={speedoMeterData?.weekly?.[0]?.percentage} open={speedoMeterData?.weekly?.[0]?.current_open ? speedoMeterData?.weekly?.[0]?.current_open : speedoMeterData?.weekly?.[0]?.ticker_open}
                                    high={speedoMeterData?.weekly?.[0]?.current_high >= speedoMeterData?.weekly?.[0]?.ticker_high ? speedoMeterData?.weekly?.[0]?.current_high : speedoMeterData?.weekly?.[0]?.ticker_high}
                                    low={speedoMeterData?.weekly?.[0]?.current_low >= speedoMeterData?.weekly?.[0]?.ticker_low ? speedoMeterData?.weekly?.[0]?.ticker_low : speedoMeterData?.weekly?.[0]?.current_low == 0 ? speedoMeterData?.weekly?.[0]?.ticker_low : speedoMeterData?.weekly?.[0]?.current_low}
                                    close={speedoMeterData?.weekly?.[0]?.current_close} poscount={speedoMeterData?.weekly?.[0]?.poscount} negcount={speedoMeterData?.weekly?.[0]?.negcount} arrow={speedoMeterData?.weekly?.[0]?.arrow} totalMetrics={speedoMeterData?.weekly?.[0]?.totalMetrics} prev_range={speedoMeterData?.weekly?.[0]?.pre_percentage}

                                />
                            </Grid>
                            {/* <CandleStickChart30min yAxisValues={dataYaxis30min_w} graphData={data30min} from30={true} keys={keys_w} data={p_p_data?.weekly?.Data} /> */}
                            <GraphForCandleStickChart token={token} min="30" companySymbol={companySymbol} tf="weeklyData" ht= "420"/>
                        </Grid>
                        <Grid className='' style={{ width: "20%", position: 'relative' }}>
                            <Grid style={{ background: '#000', display: 'flex', justifyContent: 'center', paddingTop: '3px', alignItems: 'end' }}>
                                <Typography style={{ color: getColor(Object.values(speedoMeterData)?.length > 0 ? speedoMeterData.monthly?.[0]?.percentage?.toFixed(2) : 0), marginLeft: "3px", alignItems: 'center', display: 'flex' }} className='font-futura'>
                                    <span style={{ fontFamily: 'Futura PT Heavy', color: '#fff', fontSize: '13px' }}>1 Hr &nbsp;</span>
                                    <span style={{ fontFamily: 'Futura PT Heavy', fontSize: "18px" }}> {getHold(Object.values(speedoMeterData)?.length > 0 ? speedoMeterData.monthly?.[0]?.percentage : 0)}</span>
                                    <span className='mr-1' style={{ fontSize: '13px' }}>({Object.values(speedoMeterData)?.length > 0 ? speedoMeterData.monthly?.[0]?.percentage?.toFixed(2) : 0}%)</span>
                                </Typography>
                            </Grid>
                            <Grid style={{ position: 'absolute', width: '100%', zIndex: '99', }}>
                                <CandleStickIndices percentage={speedoMeterData?.monthly?.[0]?.percentage} open={speedoMeterData?.monthly?.[0]?.current_open ? speedoMeterData?.monthly?.[0]?.current_open : speedoMeterData?.monthly?.[0]?.ticker_open}
                                    high={speedoMeterData?.monthly?.[0]?.current_high >= speedoMeterData?.monthly?.[0]?.ticker_high ? speedoMeterData?.monthly?.[0]?.current_high : speedoMeterData?.monthly?.[0]?.ticker_high}
                                    low={speedoMeterData?.monthly?.[0]?.current_low >= speedoMeterData?.monthly?.[0]?.ticker_low ? speedoMeterData?.monthly?.[0]?.ticker_low : speedoMeterData?.monthly?.[0]?.current_low == 0 ? speedoMeterData?.monthly?.[0]?.ticker_low : speedoMeterData?.monthly?.[0]?.current_low}
                                    close={speedoMeterData?.monthly?.[0]?.current_close} poscount={speedoMeterData?.monthly?.[0]?.poscount} negcount={speedoMeterData?.monthly?.[0]?.negcount} arrow={speedoMeterData?.monthly?.[0]?.arrow} totalMetrics={speedoMeterData?.monthly?.[0]?.totalMetrics} prev_range={speedoMeterData?.monthly?.[0]?.pre_percentage}

                                />
                            </Grid>
                            {/* <CandleStickChart1hr yAxisValues={dataYaxis_m} graphData={data1hr} keys={keys_m} data={p_p_data?.monthlyData} /> */}
                            <GraphForCandleStickChart token={token} min="60" companySymbol={companySymbol} tf="monthlyData" ht= "418"/>
                        </Grid>
                    </Grid>
                    {/* <Grid className='w-full flex gap-1 h-full'>
                    <Grid className='h-full' style={{ width: "55%" }}>
                        <Grid style={{ background: '#000', display: 'flex', justifyContent: 'center' }}>
                            <Typography style={{ color: getColor(Object.values(speedoMeterData)?.length > 0 ? speedoMeterData.daily[0]?.percentage?.toFixed(2) : 0), marginLeft: "3px" }} className='font-futura'>
                                <span >  {getHold(Object.values(speedoMeterData)?.length > 0 ? speedoMeterData.daily[0]?.percentage : 0)}</span>
                                <span className='mr-1' style={{ fontSize: '13px' }}> ({Object.values(speedoMeterData)?.length > 0 ? speedoMeterData.daily[0]?.percentage?.toFixed(2) : 0}%)</span>
                            </Typography>
                            <Typography noWrap align="center" style={{ color: '#fff' }} className='font-futura'>
                                <span className="text-xs font-black font-futura" style={{ color: "#DA2424" }}>
                                    {Object.values(speedoMeterData)?.length > 0 ? `(${speedoMeterData.daily[0]?.negcount}/${speedoMeterData.daily[0]?.totalMetrics})` : 0}
                                </span>
                                {getArrow(speedoMeterData.daily[0]?.arrow)}
                                <span className="text-xs font-black font-futura" style={{ color: "#339249" }}>
                                    {Object.values(speedoMeterData)?.length > 0 ? `(${speedoMeterData.daily[0]?.poscount}/${speedoMeterData.daily[0]?.totalMetrics})` : 0}
                                </span>
                            </Typography>
                        </Grid>
                        <FRECandleStickChart yAxisValues={freDataYaxis15min_d} graphData={data15min} keys={freKeys_d} data={freData?.dailyData} />
                    </Grid>
                    <Grid className='' style={{ width: "23%", marginRight: '5px' }}>
                        <Grid style={{ background: '#000', display: 'flex', justifyContent: 'center' }}>
                            <Typography style={{ color: getColor(Object.values(speedoMeterData)?.length > 0 ? speedoMeterData.weekly[0]?.percentage?.toFixed(2) : 0), marginLeft: "3px" }} className='font-futura'>
                                <span>  {getHold(Object.values(speedoMeterData)?.length > 0 ? speedoMeterData.weekly[0]?.percentage : 0)}</span>
                                <span className='mr-1' style={{ fontSize: '13px' }}>  ({Object.values(speedoMeterData)?.length > 0 ? speedoMeterData.weekly[0]?.percentage?.toFixed(2) : 0}%)</span>
                            </Typography>
                            <Typography noWrap align="center" style={{ color: '#fff' }} className='font-futura'>
                                <span className="text-xs font-black font-futura" style={{ color: "#DA2424" }}>
                                    {Object.values(speedoMeterData)?.length > 0 ? `(${speedoMeterData.weekly[0]?.negcount}/${speedoMeterData.weekly[0]?.totalMetrics})` : 0}
                                </span>
                                {getArrow(speedoMeterData.weekly[0]?.arrow)}
                                <span className="text-xs font-black font-futura" style={{ color: "#339249" }}>
                                    {Object.values(speedoMeterData)?.length > 0 ? `(${speedoMeterData.weekly[0]?.poscount}/${speedoMeterData.weekly[0]?.totalMetrics})` : 0}
                                </span>
                            </Typography>
                        </Grid>
                        <FRECandleStickChart30min yAxisValues={freDataYaxis30min_w} graphData={data30min} from30={true} keys={freKeys_w} data={freData?.weeklyData} />
                    </Grid>
                    <Grid className='' style={{ width: "20%" }}>
                        <Grid style={{ background: '#000', display: 'flex', justifyContent: 'center', paddingTop: '3px',alignItems:'end' }}>
                            <Typography style={{ color: getColor(Object.values(speedoMeterData)?.length > 0 ? speedoMeterData.monthly[0]?.percentage?.toFixed(2) : 0), marginLeft: "3px" }} className='font-futura'>
                                <span style={{fontSize:'14px'}} >  {getHold(Object.values(speedoMeterData)?.length > 0 ? speedoMeterData.monthly[0]?.percentage : 0)}</span>
                                <span className='mr-1' style={{ fontSize: '13px' }}>( {Object.values(speedoMeterData)?.length > 0 ? speedoMeterData.monthly[0]?.percentage?.toFixed(2) : 0}%)</span>

                            </Typography>
                            <Typography noWrap align="center" style={{ color: '#fff', fontSize: '11px' }} className='font-futura'>
                                <span className="text-xs font-black font-futura" style={{ color: "#DA2424" }}>
                                    {Object.values(speedoMeterData)?.length > 0 ? `(${speedoMeterData.monthly[0]?.negcount}/${speedoMeterData.monthly[0]?.totalMetrics})` : 0}
                                </span>
                                {getArrow(speedoMeterData.monthly[0]?.arrow)}
                                <span className="text-xs font-black font-futura" style={{ color: "#339249" }}>
                                    {Object.values(speedoMeterData)?.length > 0 ? `(${speedoMeterData.monthly[0]?.poscount}/${speedoMeterData.monthly[0]?.totalMetrics})` : 0}
                                </span>
                            </Typography>
                        </Grid>
                        <FRECandleStickChart1hr yAxisValues={freDataYaxis_m} graphData={data1hr} keys={freKeys_m} data={freData?.monthlyData} />
                    </Grid>
                </Grid> */}
                </> : <Typography>Loading...</Typography>}
        </>
    )
}

export default IndiceCandleGraphs