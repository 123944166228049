import { Button, Grid, IconButton, InputAdornment, Paper, TextField, Typography, Link, Snackbar, Dialog, DialogTitle, DialogContent, styled, Icon, useMediaQuery } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import './login.css';
import FormControl from "@material-ui/core/FormControl";
import { AccountCircle, Close, LockOutlined, LockRounded, VisibilityOffOutlined, VisibilityOutlined } from '@material-ui/icons';
import validateLogin from './LoginValidator';
import { useCustomForm } from '../common/useCustomForm';
import GenerateFormFields from '../common/GenerateFormFields';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { AppLogo, LoginImage } from '../icons/AppIcon';
import LoginService from './LoginService';
import { ApiService, loginApi } from '../apiService/apiService';
import { Alert } from '@material-ui/lab';
import Captcha from '../captcha/Captcha';
import { EyeSvgIcon, Hide, MailIcon, PasswordIcon } from '../../assets/svgIcons/svgIcons';
import Loader from '../common/Loader';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
 
import ForgetPassword from '../ForgetPassword/ForgetPassword'; // Import ForgetPassword component
import ResetPassword from '../ForgetPassword/ResetPassword'; // Import ResetPassword component
 
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
 
const formFields = { email: '', password: '', captcha: '' }
const Login = (props) => {
  const matches = useMediaQuery('(min-width:900px)');
  const [showPasswordTxt, setShowPasswordTxt] = useState('password');
  const [isUsernmPassError, setIsUsernmPassError] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState(formFields);
 
  // States for the dialogs
  const [showForgetPassword, setShowForgetPassword] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
 
  const notify = (sev, str) => toast[sev ? sev : 'info'](str);
  const onLogin = async () => {
    setLoading(true)
    const reqPayload = {}
    for (const key in userForm) {
      const element = userForm[key];
      reqPayload[key] = element.toString();
      //reqPayload.append(key, element.toString());
    }
    if (reqPayload.captcha) {
      delete reqPayload.captcha
    }
    if (reqPayload) {
      const logIn = await loginApi(reqPayload)
      if (logIn?.data?.success) {
        localStorage.setItem("token", logIn.data?.token);
        history.push("/home-dashboard");
        window.onpopstate = function () {
          window.history.forward();
          notify("success", `${logIn?.data?.message}`)
        };
      } else {
        setLoading(false)
        notify("warning", `${logIn?.data?.message}`)
      }
      // setLoading(false)
    }
  }
  const { errors, handleChange, handleSubmit, values: userForm, setErrors } = useCustomForm(
    fields,
    onLogin,
    validateLogin
  );
 
  const formGenMetadata = [
    {
      componentNm: 'textfield',
      // labelText: 'Email / Mobile No.',
      name: 'email',
      placeholder: 'Enter your email  ',
      classes: {
        root: 'mt-6',
      },
      isRequired: true,
      errors: errors,
      className: "w-full h-8 m-0",
      onChange: handleChange,
      startAdornment: (
        <IconButton edge="start" size="small">
          <MailIcon />
        </IconButton>
      ),
    },
    {
      componentNm: 'textfield',
      // labelText: 'Password',
      name: 'password',
      classes: {
        root: 'mt-6',
      },
      isRequired: true,
      type: showPasswordTxt,
      placeholder: 'Enter your password',
      errors: errors,
      className: "w-full h-8 m-0",
      onChange: handleChange,
      onKeyPress: (e) => {
        e.persist()
        if (e.key === "Enter") {
          handleSubmit(e)
        }
        // e.key === "Enter" && handleSubmit(e)
      },
      startAdornment: (
        <IconButton edge="start" size="small">
          <PasswordIcon />
        </IconButton>
      ),
      endAdornment: (
        <IconButton
          size="small"
          onClick={() =>
            setShowPasswordTxt(
              showPasswordTxt === 'password' ? 'text' : 'password'
            )
          }
          color="primary"
        >
          {showPasswordTxt === 'password' ? (
           <Hide />
          ) : (
            <EyeSvgIcon />
          )}
        </IconButton>
      ),
    },
  ];
  const onLogInClick = (event) => {
    props.setShowLogin(true)
  };
 
  const onDisappearDilog = () => {
    setFields(formFields)
    setErrors({})
    setShowPasswordTxt('password')
    setIsUsernmPassError(false)
  }
 
  const handleClose = (event, reason) => {
    onDisappearDilog()
    props.setShowLogin(false)
  };
 
  return (
 
    <Grid>
      <Button onClick={onLogInClick} className={props.fromHome ? "btn-get-started" : ''}
        style={props.fromHome ? { color: "#ffffff" } : { backgroundColor: "#154A72", color: "#ffffff", borderRadius: "7px" }}
      >
        {props.fromHome ? `Get Started` : "Log In"}
      </Button>
      <Dialog className='dialog-box' onClose={handleClose} open={props.showLogin}>
 
        <IconButton
          aria-label="close"
          onClick={handleClose}
          className='close-icon'
          style={{ justifyContent: 'end' }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <Grid className='dialog-content' style={{fontFamily:'Futura PT Book'}}>
          {matches && <Grid className='image-container'>
            <Grid className='company-logo'><AppLogo height='auto' width="200px" classes='w-half' /></Grid>
            <Grid className='login-image'><LoginImage height='auto' width="200px" /></Grid>
            <Grid className='info mt-4'>
              {/* <Typography className='info-headding'>Lorem ipsum dolor sit amet</Typography> */}
              <Typography className='info-content'>
                “At Tradabulls, we are dedicated to providing the best stock market research and resources to help investors make informed decisions and achieve their financial goals."
              </Typography>
            </Grid>
          </Grid>}
          <Grid className="login-container" >
            <Typography className="welcom-text" >
              Welcome To TradaBulls
            </Typography>
            <Typography className="login-text" >
              Please enter you login details
            </Typography>
            <Grid item className="login-card">
              <Paper className='login-paper' style={{ boxShadow: 'none' }} >
                <Grid>
                  <GenerateFormFields argsMeta={formGenMetadata} />
                </Grid>
                {
                  isUsernmPassError && (
                    <Typography className="text-sm mt-2" color="error">
                      Either username or password is incorrect. Please check.
                    </Typography>
                  )
                }
                <Grid>
                  <Typography className="text-xs mt-2" style={{ display: 'flex', justifyContent: 'end' }}>
                    <span
                      style={{ color: '#154A72', fontSize: '12px', fontWeight: 'bold', cursor: 'pointer' }}
                      onClick={() => setShowForgetPassword(true)} // Open ForgetPassword dialog
                    >
                      Forgot Password?
                    </span>
                  </Typography>
                </Grid>
                <Grid style={{ display: 'grid' }}>
                  <Captcha
                    // buttonLabel='Log in'
                    errors={errors}
                    userForm={userForm}
                    handleSubmit={handleSubmit}
                  />
                </Grid>
                <Grid className=" mt-3 login-button">
                  <Button
                    size={'small'}
 
                    onClick={handleSubmit}
                  >
                    Login
                  </Button>
                </Grid>
 
                <Grid
                // item
                // className={clsx('mt-6', {
                //   hidden: isAdmin,
                // })}
                // container
                // xs={12}
                >
                  <Grid style={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>
                    <Typography className="text-xs mt-1" >
                      Don't have an account?
                      <span onClick={() => {
                        props.changeToSignUp()
                      }}
                        color="primary"
                        className="underline text-xs ml-2 mt-1"
                        style={{ cursor: "pointer" }}
                      >
                        Register Here
                      </span>
                    </Typography>
                    {/* <RouterLink
                      to={
 
                        '/signup'
                      }
                    >
                      <Link
                        color="primary"
                        className="underline text-xs ml-2 mt-1"
                        component="button"
                      >
                        Register Here
                      </Link>
                    </RouterLink>*/}
                  </Grid>
 
                  {/* <Grid item container justifyContent='center' alignItems="center" xs={6} sm={6}>
                    <Typography className="text-xs mt-1">
                      Not a member?
                    </Typography>
                    <RouterLink to="/">
                      <Link
                        color="primary"
                        className="underline text-xs ml-2 mt-1"
                        component="button"
                      >
                        Forgot password?
                      </Link>
                    </RouterLink>
                  </Grid> */}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
 
        {/* Forget Password Dialog */}
        <BootstrapDialog
          onClose={() => setShowForgetPassword(false)}
          open={showForgetPassword}
          maxWidth="sm"
          fullWidth
        >
          <ForgetPassword />
        </BootstrapDialog>
 
        {/* Reset Password Dialog */}
        <BootstrapDialog
          onClose={() => setShowResetPassword(false)}
          open={showResetPassword}
          maxWidth="sm"
          fullWidth
        >
          <ResetPassword />
        </BootstrapDialog>
 
        {loading && <Loader />}
      </Dialog>
      <ToastContainer type="warning" position="top-right" draggable={false} pauseOnHover={false} />
    </Grid>
  );
}
 
export default Login