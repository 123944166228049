import axios from "axios";
import { tickerAPI } from "../../utils/config";

export const tickerApiService = axios.create({
    baseURL: `${tickerAPI}`,
});

export const CurrentCompanyData = async (token) => {
    return await tickerApiService.get(`/tick-data/by-token?token=${token}`).then((res) => { return res }).catch((err) => { return err })
};

export const ITNiftyData = async () => {
    return await tickerApiService.get("/tick-data/by-token?token=259849").then((res) => { return res }).catch((err) => { return err })
};

export const BankNiftyData = async () => {
    return await tickerApiService.get("/tick-data/by-token?token=260105").then((res) => { return res }).catch((err) => { return err })
};

export const NiftyData = async () => {
    return await tickerApiService.get("/tick-data/by-token?token=256265").then((res) => { return res }).catch((err) => { return err })
};
export const IndexMoversData= async (symbol,CMP,timeFrame) => {
    return await tickerApiService.get(`/${symbol}?currentIndexPrice=${CMP}&timeframe=${timeFrame}`).then((res) => { return res }).catch((err) => { return err })
};

export const HeatsMapData = async (a) => {
    return await tickerApiService.get(`/${a}/changePercentages`).then((res) => { return res }).catch((err) => { return err })
};

export const NiftyPlusData = async (tf) => {
    return await tickerApiService.get(`/niftyplus?currentIndexPrice=0&timeframe=${tf}`)
}
export const NsePlusData = async (tf) => {
    return await tickerApiService.get(`/nseplus?currentIndexPrice=0&timeframe=${tf}`)
}

export const NiftyTenData = async (tf) => {
    return await tickerApiService.get(`/niftyten?currentIndexPrice=0&timeframe=${tf}`)
}

export const GetHeatmapsObservations = async (indice,h_m_keyForApi) => {
    return await tickerApiService.get(`/${indice}/${h_m_keyForApi}`)
}

export const GetObservationsForCandle = async (tf,company,token) => {
    let encodedSymbol = encodeURIComponent(company);
    return await tickerApiService.get(`with-arrows?timeframe=${tf}&symbol=${encodedSymbol}&token=${token}`)
}

export const IndexFuturesData = async (tf) => {
    return await tickerApiService.get(`/futures?currentIndexPrice=0&timeframe=${tf}`)
}

export const IndexMoversBankTenData = async (tf) => {
    return await tickerApiService.get(`/bankten?currentIndexPrice=0&timeframe=${tf}`)
}
export const IndexMoversItTenData = async (tf) => {
    return await tickerApiService.get(`/itniftyten?currentIndexPrice=0&timeframe=${tf}`)
}

export const IndexMoversMidCapSelectTenData = async (tf) => {
    return await tickerApiService.get(`/midcapselectten?currentIndexPrice=0&timeframe=${tf}`)
}

export const IndexMoversFinNiftyTenData = async (tf) => {
    return await tickerApiService.get(`/finniftyten?currentIndexPrice=0&timeframe=${tf}`)
}

export const NiftyPlusPlus = async (indice,token) => {
    let encodedSymbol = encodeURIComponent(indice);
    return await tickerApiService.get(`/nseplusplus?symbol=${encodedSymbol}&token=${token}`)
}

export const candleGraphFor15min = async (token,fromDate,toDate,min) => {
    return await tickerApiService.get(`/fetch?instrumentToken=${token}&fromDate=${fromDate}+09:10:00&toDate=${toDate}+16:13:00&interval=${min}minute`)
}

export const candleGraphApi = async (token, fromDate, toDate, min) => {
    return await tickerApiService.get(`/fetch?instrumentToken=${token}&fromDate=${fromDate}+09:10:00&toDate=${toDate}+16:13:00&interval=${min}minute`)
}