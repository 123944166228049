import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import LandingComponent from './components/landingPage/Landing';
import Login from './components/Login/Login';
import Home from './components/HomePage/HomePage';
import Signup from './components/Signup/Signup'
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import SiteFooter from './components/landingPage/SiteFooter';
import VideoBar from './components/landingPage/VideoBar';
import ServicePage from './components/landingPage/ServicePage';
import Aboutus from './components/landingPage/Aboutus';
import ExploreUs from './components/landingPage/ExploreUs';
import ForgetPassword from './components/ForgetPassword/ForgetPassword';
import ResetPassword from './components/ForgetPassword/ResetPassword';
import Blogs from './components/landingPage/Blogs';
import Day1Blog from './components/landingPage/Day1Blog';
import Day2Blog from './components/landingPage/Day2Blog';
import Day3Blog from './components/landingPage/Day3Blog';
import Day4Blog from './components/landingPage/Day4Blog';
import Day5Blog from './components/landingPage/Day5Blog';
import Day6Blog from './components/landingPage/Day6Blog';
import Day7Blog from './components/landingPage/Day7Blog';
import Day8Blog from './components/landingPage/Day8Blog';
import Day9Blog from './components/landingPage/Day9Blog';
import Day10Blog from './components/landingPage/Day10Blog';
import Day11Blog from './components/landingPage/Day11Blog';


// TODO: this on should have only 3 routes
// 1. login
// 2. landing page
// 3. rest password
// 4. change password
// 5. menu home

export default function Routes() {
  // const location = useLocation();
  // useEffect(() => {
  //   const token = localStorage.getItem('token')
  //   if (!token && location.pathname === '/') { 
  //     // Remove any existing Tawk.to script
  //     const existingScript = document.querySelector('script[src="https://embed.tawk.to/66b3660832dca6db2cbaf4bb/1i4mcd4mb"]');
  //     if (existingScript) {
  //       existingScript.remove();
  //       if (window.Tawk_API) {
  //         delete window.Tawk_API;
  //       }
  //     }
  //     const script = document.createElement('script');
  //     script.type = 'text/javascript';
  //     script.async = true;
  //     script.src = 'https://embed.tawk.to/66b3660832dca6db2cbaf4bb/1i4mcd4mb';
  //     script.charset = 'UTF-8';
  //     script.setAttribute('crossorigin', '*');
  //     document.body.appendChild(script);
  //   }
  // }, [location]);

  return (
    <Switch>
      {/* <Route component={RestPasswordLink} path="/request-resetPassword" />
      <Route component={ForgotPassword} path="/resetPassword" />
      <Route component={JobSeekerSteppers} path="/jobseeker-register" />
      <Route component={JobSeekerSteppers} path="/edit-profile" />
      <Route component={EmpRegistrationPage} path="/signup" />
      <Route component={EmpRegistrationPage} path="/edit-employer" />
      <Route component={MenuHome} path="/home" />
      <Route component={Agrora} path="/interview" />
      <Route component={Walkins} path='/walk-in' />
      <Route component={Index} path="/call" />
      <Route component={Meeting} path="/meeting" />
      <Route
        exact
        component={() => {
          h2oService.currentLoginUser = 'Admin';
          return <Login />;
        }}
        path="/admin"
      />*/}
      <Route component={Home} path="/home-dashboard" />
      <Route exact component={ServicePage} path="/service" />
      <Route exact component={Aboutus} path="/about" />
      <Route exact component={ExploreUs} path="/exploreus" />
      <Route exact component={Blogs} path="/blogs" />
      {/* <Route exact component={Day1Blog} path="/10092024" />
      <Route exact component={Day2Blog} path="/11092024" /> */}
      <Route exact component={Day3Blog} path="/12092024" />
      <Route exact component={Day4Blog} path="/13092024" />
      <Route exact component={Day5Blog} path="/16092024" />
      <Route exact component={Day6Blog} path="/17092024" />
      <Route exact component={Day7Blog} path="/18092024" />
      <Route exact component={Day8Blog} path="/19092024" />
      <Route exact component={Day9Blog} path="/20092024" />
      <Route exact component={Day10Blog} path="/23092024" />
      <Route exact component={Day11Blog} path="/24092024" />
      {/* <Route component={Home} path="/home-dashboard" />
      <Route exact component={Login} path="/login" />
      <Route exact component={Signup} path="/signUp" />
      <Route component={LandingComponent} path="/" />

    </Switch> */}


      <Route path="/home-dashboard" component={Home} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/signUp" component={Signup} />
      <Route path="/reset-password" component={ResetPassword} />
      <Route path="/" component={LandingComponent} />
    </Switch>
  );
}
