import React, { useState } from 'react';
import './BlogPage.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import pdf1 from '../../assets/pdf/2309BLOGS_01.pdf';
import pdf2 from '../../assets/pdf/2309BLOGS_02.pdf';
import { Viewer, Worker } from '@react-pdf-viewer/core';

const Day10Blog = () => {
    const [activePdf, setActivePdf] = useState(pdf1);
    const history = useHistory();
    const handleNavigate = (path) => {
        history.push(path);
        window.scrollTo({ top: 0, behavior: 'smooth' });  // Scroll to top with smooth behavior
        // setShowSidebar(false);  // Optionally close the sidebar after navigation
        // setActiveTab(path);
    };
    return (
        <div>
            <div>
                {/* <LandingHeader /> */}
                <button onClick={() => handleNavigate("/blogs")} style={{
                    marginTop: '20px',
                    padding: '10px 20px',
                    backgroundColor: '#007bff',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    fontSize: '16px'
                }}>
                    Back to Today's Blog
                </button>

            </div>
            <div className='pdf-viewer'>
                    <div className='button-group'>
                        <button className={`toggle-button ${activePdf === pdf1 ? 'active' : ''}`} onClick={() => setActivePdf(pdf1)}>Blog 1</button>
                        <button className={`toggle-button ${activePdf === pdf2 ? 'active' : ''}`}  onClick={() => setActivePdf(pdf2)}>Blog 2</button>
                    </div>
                    <Worker style={{ width: '100%' }} workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
                        <Viewer fileUrl={activePdf} />
                        {/* <Viewer fileUrl={pdf} /> */}
                    </Worker>
                </div>
        </div>


    );
};

export default Day10Blog;
